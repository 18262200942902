import React, { useState } from "react";
import "react-modal-video/css/modal-video.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Video from "../Generic/Video";

const Activities = (activity) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setURL] = useState("");
  const handleVideoModal = () => {
    setIsOpen(!isOpen)
  }

  const VideoBackGroundImage = styled.div`
  background-image: linear-gradient(rgba(18, 19, 24, 0.5),rgba(18, 19, 24, 0.9)),url(${activity.activity.video_thumb_url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;
  return (
    <React.Fragment>
      <div className="activities-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="section-title primary3">
                <span>-{activity.activity.title}-</span>
                <h3>{activity.activity.highlist_title}</h3>
                <p className="para">
                  {activity.activity.sub_title}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="activities-area">
              <VideoBackGroundImage
                className="company-vdo position-relative wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.2s"
              >
                <div className="video-play style-2">
                  <div
                    style={{ cursor: "pointer" }}
                    className="popup-youtube video-icon">
                    <a onClick={() => {
                      setURL(activity.activity.video_url)
                      handleVideoModal()
                    }}
                      href={(e) => e.preventDefault}>
                      <i className="bx bx-play" />
                    </a>
                  </div>
                </div>
              </VideoBackGroundImage>
              <div
                className="company-counter wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="0.2s"
              >
                <div className="row g-4 d-flex justify-content-center">
                  {
                    activity.activity.client_review_section.length &&
                    activity.activity.client_review_section.map(review => {
                      return (
                        <div className="col-lg-3 col-md-6 col-sm-10 col-10">
                          <div
                            className="counter-single text-center d-flex flex-row wow animate fadeInDown"
                            data-wow-duration="1.5s"
                            data-wow-delay="0.6s"
                          >
                            <div className="coundown d-flex flex-column">
                              <h2 className="odometer">
                                {review.count} +
                              </h2>
                              <Link href="#">{review.title}</Link>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        isOpen &&
        <Video handleVideoModal={handleVideoModal} url={url} />
      }
    </React.Fragment>
  );
}

export default Activities;
