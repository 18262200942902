import axios from "axios";
import * as Constants from "./Action";

export const getContactUsDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_CONTACT_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetContactUsDetails";
  const body = {
    ClientId: 48,
    StoreId: 1,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_CONTACT_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        dispatch({
          type: Constants.GET_CONTACT_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_CONTACT_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getAboutUsDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_ABOUT_US_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetAboutDetails";
  const body = {
    ClientId: 48,
    StoreId: 1,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_ABOUT_US_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        dispatch({
          type: Constants.GET_ABOUT_US_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_ABOUT_US_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getTeamDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_TEAM_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetTeamDetails";
  const body = {
    ClientId: 48,
    StoreId: 1,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_TEAM_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        dispatch({
          type: Constants.GET_TEAM_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_TEAM_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getConferenceDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_CONFERENCE_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetEventDetails";
  const body = {
    ClientId: 48,
    StoreId: 1,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_CONFERENCE_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        dispatch({
          type: Constants.GET_CONFERENCE_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_CONFERENCE_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getHistoryDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_HISTORY_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetHistory";
  const body = {
    ClientId: 48,
    StoreId: 1,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_HISTORY_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        dispatch({
          type: Constants.GET_HISTORY_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_HISTORY_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getBlogDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_BLOG_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetBlogs";
  const body = {
    ClientId: 48,
    PageNo: 1,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_BLOG_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        console.log(res);
        dispatch({
          type: Constants.GET_BLOG_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_BLOG_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getCommonDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_COMMON_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetCommonDetails";
  const body = {
    ClientId: 48,
    StoreId: 1,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_COMMON_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        // console.log(res);
        dispatch({
          type: Constants.GET_COMMON_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_COMMON_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getHomeDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_HOME_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetHomeDetails";
  const body = {
    ClientId: 48,
    StoreId: 1,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_HOME_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        console.log(res);
        dispatch({
          type: Constants.GET_HOME_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_HOME_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getSaluteDetails = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_SALUTE_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetAboutSalutes";
  const body = {
    ClientId: 48,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_SALUTE_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        console.log(res);
        dispatch({
          type: Constants.GET_SALUTE_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_SALUTE_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getStates = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_STATE_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "StateList";
  const body = {
    ClientId: 48,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_STATE_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        console.log(res);
        dispatch({
          type: Constants.GET_STATE_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_STATE_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const getPartnerDeatils = async (appState, dispatch) => {
  dispatch({
    type: Constants.GET_PARTNER_DETAILS,
    payload: {},
    loader: true,
  });
  let requestURL = process.env.REACT_APP_URL + "GetPartnerAndRetailsDetails";
  const body = {
    ClientId: 48,
  };
  let key = requestURL + JSON.stringify(body);
  let globalCache = appState.storedValues.hasOwnProperty(key);
  if (globalCache) {
    dispatch({
      type: Constants.GET_PARTNER_DETAILS,
      payload: appState.storedValues[key],
      loader: false,
    });
  } else
    await axios
      .post(requestURL, body)
      .then((res) => {
        console.log(res);
        dispatch({
          type: Constants.GET_PARTNER_DETAILS,
          payload: res.data.data,
          loader: false,
        });
        dispatch({
          type: Constants.STORE_VALUES,
          payload: res.data.data,
          key: key,
        });
      })
      .catch((error) => {
        dispatch({
          type: Constants.GET_PARTNER_DETAILS,
          payload: {},
          loader: false,
        });
      });
};

export const verifyEmail = async (appState, dispatch, data) => {
  dispatch({
    type: Constants.EMAIL_VERIFICATION,
    payload: {},
    loader: true,
    message: "",
    statusmessage: "",
  });
  let requestURL = process.env.REACT_APP_URL + "VerifyInvitationInfo";
  const body = {
    ClientId: "48",
    email_id: data,
  };
  await axios
    .post(requestURL, body)
    .then((res) => {
      dispatch({
        type: Constants.EMAIL_VERIFICATION,
        payload: res.data.data,
        loader: false,
        message: res.data.status,
        statusmessage: res.data.message,
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.EMAIL_VERIFICATION,
        payload: {},
        loader: false,
        message: error.data.status,
        statusmessage: error.data.message,
      });
    });
};

export const getEventIds = async (appState, dispatch, data) => {
  dispatch({
    type: Constants.GET_EVENT_IDS,
    payload: {},
    loader: true,
    message: "",
  });
  let requestURL = process.env.REACT_APP_URL + "GetInvitationWiseEventsList";
  await axios
    .post(requestURL, data)
    .then((res) => {
      dispatch({
        type: Constants.GET_EVENT_IDS,
        payload: res.data,
        loader: false,
        message: res.data.status,
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.GET_EVENT_IDS,
        payload: {},
        loader: false,
        message: error.data.status,
      });
    });
};

export const registerData = async (appState, dispatch, data) => {
  dispatch({
    type: Constants.REGISTER_EVENT,
    payload: {},
    loader: true,
    message: "",
  });
  let requestURL = process.env.REACT_APP_URL + "RegisterEvent";
  let event = data.eventIds.map((info) => parseInt(info, 10));
  let guestEvent = data.guestEventIds.map((info) => parseInt(info, 10));
  let selectedOption = data.selectedList.map((info) => parseInt(info, 10));
  let wholesaler_ids =
    data.wholesalerlist.length > 0
      ? data.wholesalerlist.map((info) => parseInt(info.customer_id, 10))
      : [];
  let wholesalerListUpdated =
    data.wholesalerlist.length > 0
      ? data.wholesalerlist.map((info) => {
          return { company_id: info.customer_id, event_id: info.event_id };
        })
      : [];

  let Golf_event_id = data.selectedFinalDateList?.filter(
    (each) => each.sub_event_list.length > 0
  )[0]?.event_id;
  let sub_event_condition = event.includes(parseInt(Golf_event_id));
  let Guest_event_condition = guestEvent.includes(parseInt(Golf_event_id));

  let body = {
    ClientId: 48,
    email_id: data.email,
    registration_type_id: data.selectedType,
    timezone: "Asia/kolkata",
    registration_sub_type_id: data.selectedSubType,
    first_name: data.fname,
    last_name: data.lname,
    company_name: data.company,
    logo_url: data.logo,
    city: data.city,
    state_name: data.state,
    zip_code: data.zip_code,
    password: "1234",
    phone_number: data.mobile,
    address: data.address,
    event_ids: event,
    guest_first_name: data.guestFname,
    guest_last_name: data.guestLname,
    guest_phone_number: data.guestMobile,
    sponsors_bonus_option_ids: selectedOption,
    amount: data.amount,
    guest_event_ids: guestEvent,
    wholesaler_ids: wholesaler_ids,
    wholesaler_list: wholesalerListUpdated,
    time_slots: data.time_slot,
    sub_event_list: sub_event_condition
      ? data.sub_event_list.flat(Infinity)
      : [],
    guest_sub_event_list: Guest_event_condition
      ? data.guest_sub_event_list.flat(Infinity)
      : [],
  };

  await axios
    .post(requestURL, body)
    .then((res) => {
      dispatch({
        type: Constants.REGISTER_EVENT,
        payload: res.data,
        loader: false,
        message: res.data.message,
      });
    })
    .catch((error) => {
      dispatch({
        type: Constants.REGISTER_EVENT,
        payload: {},
        loader: false,
        message: error.data.message,
      });
    });
};

export const submitInvitationReq = async (appState, dispatch, data) => {
  dispatch({
    type: Constants.SAVE_INVITATION_DETAILS,
    payload: {},
    loader: true,
    message: "",
  });
  let requestURL = process.env.REACT_APP_URL + "RequestInvitation";
  await axios
    .post(requestURL, data)
    .then((res) => {
      dispatch({
        type: Constants.SAVE_INVITATION_DETAILS,
        payload: res.data,
        loader: false,
        message: res.data.message,
      });
    })
    .catch((error) => {
      debugger;
      dispatch({
        type: Constants.SAVE_INVITATION_DETAILS,
        payload: {},
        loader: false,
        message: error.data.message,
      });
    });
};

export const loginRequest = async (appState, dispatch, data) => {
  dispatch({
    type: Constants.GET_LOGGED_IN,
    payload: {},
    loader: true,
    message: "",
  });
  let requestURL = process.env.REACT_APP_URL + "CustomerLogin";
  await axios
    .post(requestURL, data)
    .then((res) => {
      dispatch({
        type: Constants.GET_LOGGED_IN,
        payload: res.data,
        loader: false,
        message: res.data.message,
      });
      localStorage.setItem("loggedInEmail", res.data.data.email_id);
    })
    .catch((err) => {
      debugger;
      dispatch({
        type: Constants.GET_LOGGED_IN,
        payload: {},
        loader: false,
        message: err.data.message,
      });
    });
};

export const getEventsListRequest = async (appState, dispatch, data) => {
  dispatch({
    type: Constants.GET_EVENTS_LIST,
    payload: {},
    loader: true,
    message: "",
  });
  let requestURL = process.env.REACT_APP_URL + "GetEventsList";
  await axios
    .post(requestURL, data)
    .then((res) => {
      dispatch({
        type: Constants.GET_EVENTS_LIST,
        payload: res.data,
        loader: false,
        message: res.data.message,
      });
    })
    .catch((err) => {
      debugger;
      dispatch({
        type: Constants.GET_EVENTS_LIST,
        payload: {},
        loader: false,
        message: err.data.message,
      });
    });
};

export const getRMNDataRequest = async (appState, dispatch, data) => {
  dispatch({
    type: Constants.GET_RMN_DATA,
    payload: {},
    loader: true,
    message: "",
  });
  let requestURL = process.env.REACT_APP_URL + "GetRMN";

  await axios
    .post(requestURL, data)
    .then((res) => {
      dispatch({
        type: Constants.GET_RMN_DATA,
        payload: res.data,
        loader: false,
        message: res.data.message,
      });
    })
    .catch((err) => {
      debugger;
      dispatch({
        type: Constants.GET_RMN_DATA,
        payload: {},
        loader: false,
        message: err.data.message,
      });
    });
};
