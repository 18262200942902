import './App.css';
import React from 'react';
import "./public/assets/css/all.css";
import "./public/assets/css/bootstrap.min.css";
import "./public/assets/css/boxicons.min.css";
import "./public/assets/css/bootstrap-icons.css";
import "./public/assets/css/swiper-bundle.css";
import "./public/assets/css/animate.css";
import './public/assets/style.scss'
import "react-modal-video/css/modal-video.css";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import ContextProvider from './components/Redux/ContextProvider';
import Layout from './components/Layout';

function App() {
  return (
    <BrowserRouter>
      <ContextProvider>
        <Layout />
      </ContextProvider>
    </BrowserRouter>

  );
}

export default App;
