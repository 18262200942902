import React, { useContext, useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import { getHistoryDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import HistoryDetails from "./HistoryDetails";

const History = () => {
  const { appState, dispatch } = useContext(AppContext);
  const { history, historyLoader } = appState;
  const myRef = useRef(null);

  useEffect(() => {
    getHistoryDetails(appState, dispatch);
  }, []);

  return (
    <React.Fragment>
      {historyLoader ? (
        <div className="home-spinner">
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        <React.Fragment>
          <Breadcrumb
            pageName={history.header_services_section.title}
            pageTitle={history.header_services_section.title}
            id={`#${history.header_services_section.title}`}
            image={history.header_services_section.banner_image}
            myRef={myRef}
          />
          {history.services_section &&
            history.services_section.length &&
            history.services_section.map((service) => {
              return (
                <div ref={myRef}>
                  <HistoryDetails history={service} />
                </div>
              );
            })}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default History;
