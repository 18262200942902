import React from "react";

function EventsDisplay({ data }) {
  console.log(data);
  return (
    <>
      <div className="service2-section pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-title2 primary4">
                <span>-Events-</span>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4">
            {data?.length > 0 &&
              data.map((each, i) => {
                return (
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div
                      className="service-item2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.2s"
                    >
                      <div className="service-content">
                        <span>
                          <h4>
                            <i class="bi bi-clock"></i> {each.event_start_time}-
                            {each.event_end_time}
                          </h4>
                        </span>
                        <h3>{each.event_name}</h3>
                        <p className="para">{each.short_description}</p>
                        <p>Venue : {each.venue_name}</p>
                        <p>Attendees : {each.attendee_limit}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default EventsDisplay;
