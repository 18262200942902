import React, { useContext, useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import { getAboutUsDetails, getTeamDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import Team from "../Teams/Team";
import AboutContent from "../About/AboutContent";
import Activities from "../About/Activities";
import Video from "../Generic/Video";
import "react-modal-video/css/modal-video.css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ReactPlayer from "react-player";

const PastEvents = () => {
  const { appState, dispatch } = useContext(AppContext);
  const { aboutUs, aboutUsLoader } = appState;
  const { ourTeam, ourTeamLoader } = appState;
  const myRef = useRef(null);

  useEffect(() => {
    getAboutUsDetails(appState, dispatch);
  }, []);

  useEffect(() => {
    getTeamDetails(appState, dispatch);
  }, []);

  return (
    <React.Fragment>
      {aboutUsLoader ? (
        <div className="home-spinner">
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        Object.keys(aboutUs).length && (
          <React.Fragment>
            <Breadcrumb
              pageName="Past Events"
              pageTitle="Past Events"
              id={`#${aboutUs.header_services_section.title}`}
              image={aboutUs.header_services_section.banner_image}
              myRef={myRef}
            />
            <div ref={myRef}>
              <AboutContent about={aboutUs} />
              {/* <Activities activity={aboutUs.activites_section} /> */}
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8" style={{ marginTop: "60px" }}>
                <div className="section-title primary3">
                  <span>-Our Past Virtual Conference-</span>
                  {/* <h3>Our Company Activities</h3> */}
                  <p className="para">
                    The immensity of the ROFDA organization, which spans the
                    entire nation, builds a solid cooperative foundation that is
                    profitable to all of our members. We intend to collaborate
                    and assist our members as they respond to the necessities of
                    respective Independent Retail Grocers.
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "15px",
                  }}
                >
                  <div style={{ margin: "30px" }}>
                    <h5 style={{textAlign:"center"}}>Opening Comments- Jan 31st</h5>
                    <ReactPlayer
                      className="react-player"
                      url="https://www.dropbox.com/s/o73x84rxw6mbp8e/ROFDA%202023%20Closing%20Remarks%20Clipped.mp4?dl=0"
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                  </div>

                  <div style={{ margin: "30px" }}>
                    <h5 style={{textAlign:"center"}}>Closing Comments- Feb 1st</h5>
                    <ReactPlayer
                      className="react-player"
                      url="https://www.dropbox.com/s/4ur9x4tyc9fbahp/ROFDA%202023%20Opening%20Comments%20Clipped.mp4?dl=0"
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                  </div>

                  <div style={{ margin: "30px" }}>
                    <h5 style={{textAlign:"center"}}>NGA Update</h5>
                    <ReactPlayer
                      className="react-player"
                      url="https://www.dropbox.com/s/o73x84rxw6mbp8e/ROFDA%202023%20Closing%20Remarks%20Clipped.mp4?dl=0"
                      width="100%"
                      height="100%"
                      controls={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default PastEvents;
