import React, { useContext, useRef } from "react";
import { AppContext } from "../Redux/ContextProvider";
import Breadcrumb from "../Generic/Breadcrumb";
import { ClipLoader } from "react-spinners";
import EventsDisplay from "./EventsDisplay";

function MyEvents() {
  const { appState, dispatch } = useContext(AppContext);
  const { loginInfo, loginLoader } = appState;
  const { registered_event_list } = loginInfo.data;
  const myRef = useRef(null);
  // console.log(registered_event_list);

  return (
    <React.Fragment>
      {loginLoader ? (
        <div className="home-spinner">
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        <React.Fragment>
          <Breadcrumb
            pageName="My Events"
            pageTitle="My Events"
            id=""
            image=""
            myRef={myRef}
          />
          <div ref={myRef}>
            <EventsDisplay data={registered_event_list} />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default MyEvents;
