import React, { useEffect, useContext, useRef } from "react";
import { getEventsListRequest } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import { Col } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import EventsDisplay from "./EventsDisplay";

function AllEvents() {
  const { appState, dispatch } = useContext(AppContext);
  const myRef = useRef(null);
  const { eventsListInfo, eventsListLoader } = appState;
  // console.log(eventsListInfo);

  useEffect(() => {
    let data = {
      ClientId: 48,
      timezone: "asia/kolkata",
    };
    getEventsListRequest(appState, dispatch, data);
  }, []);

  return (
    <React.Fragment>
      {eventsListLoader ? (
        <div className="home-spinner">
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        <React.Fragment>
          <Breadcrumb
            pageName="All Events"
            pageTitle="All Events"
            id=""
            image=""
            myRef={myRef}
          />
          <div ref={myRef}>
            <EventsDisplay data={eventsListInfo.data} />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default AllEvents;
