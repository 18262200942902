import React, { useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "./table.css";

const WholesalerModal = ({
  handleClose,
  show,
  wholesalertimelsot,
  handleTimeslot,
  selectWholesaler,
  handleDelete,
  allwholesaler,
  options,
  disable,
  wholesaler,
  timeslots,
  allValues,
  setAllValues,
  timeslot,
  value,
  rangestate,
  wholesaleListSave,
}) => {
  useEffect(() => {
    setAllValues(value.wholesalerIds);
  }, []);

  const handleSave = () => {
    let val = { ...value, rangestate: rangestate };
    wholesaleListSave(val);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Wholesaler</Modal.Title>
      </Modal.Header>

      <ToastContainer />

      <Modal.Body>
        {/* {allwholesaler.length > 0 ?

                    <div className="row" style={{ columns: 'auto 2', margin: '20px', padding: '10px' }}>

                        <Form.Label>Event Timeslot:</Form.Label>

                        <select name="contact_question_option" className="select_questions" style={{
                            border: '1px solid #dddddd',
                            borderRadius: '5px',
                            padding: '15px 15px',
                            width: '100%',
                            transition: 'all 0.5s ease'
                        }}

                            onChange={(e) => handleTimeslot(e)}
                            disabled={disable}
                            value={timeslot}
                        >
                            <option style={{
                                fontSize: '14px',
                                fontWeight: 400,
                                fontFamily: "Poppins"
                            }}

                                value="">Select Time</option>
                            {
                                options.map((val, index) => {
                                    return (
                                        <option value={val.value}>{val.value}</option>
                                    )

                                }
                                )
                            }

                        </select>
                    </div>
                    : ''} */}

        <div
          className="row"
          style={{ columns: "auto 2", margin: "20px", padding: "10px" }}
        >
          <Form.Label>Select Wholesaler:</Form.Label>

          {allwholesaler.length > 0 ? (
            <Col xs="12" sm="12" style={{ textAlign: "left" }}>
              <select
                name="contact_question_option"
                className="select_questions"
                style={{
                  border: "1px solid #dddddd",
                  borderRadius: "5px",
                  padding: "15px 15px",
                  width: "100%",
                  transition: "all 0.5s ease",
                }}
                onChange={(e) => selectWholesaler(e)}
                disabled={disable}
                value={wholesaler}
              >
                <option
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,

                    fontFamily: "Poppins",
                  }}
                  value=""
                >
                  Select Wholesaler
                </option>
                {allwholesaler.map((val, idx) => {
                  return (
                    <option value={val.customer_id} data-name={val.first_name}>
                      {val.company_name}
                    </option>
                  );
                })}
              </select>
            </Col>
          ) : (
            ""
          )}
        </div>

        {allValues.length > 0 ? (
          <div
            className="row"
            style={{ columns: "auto 2", margin: "20px", padding: "10px" }}
          >
            <Col xs="12" sm="12" style={{ textAlign: "left", margin: "12px" }}>
              <table>
                <thead>
                  <tr>
                    {/* <th>Timeslot</th> */}
                    <th>Wholesaler Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allValues.map((val, idx) => {
                    return (
                      <tr>
                        {/* <td data-column="First Name">{val.timeslot} </td> */}
                        <td data-column="Last Name"> {val.name}</td>
                        <td
                          data-column="Job Title"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            variant="secondary"
                            name={val.name}
                            onClick={(e) => handleDelete(e, idx)}
                          >
                            <i class="bx  bx-x"></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="success" onClick={handleSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WholesalerModal;
