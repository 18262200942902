import React from "react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import QuoteWhite from '../../public/assets/images/icons/quote-white.svg'
import TestBg from '../../public/assets/images/bg/testi2-bg.svg'
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, Autoplay, Navigation]);
function Teastimonial({ testimonial }) {
  const testimonialSlider = {
    slidesPerView: "auto",
    speed: 1200,
    spaceBetween: 25,
    loop: true,
    autoplay: true,
    roundLengths: true,
    navigation: false,
    pagination: {
      el: ".swiper-pagination2",
      clickable: "true",
    },
    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 2,
      },
    },
  };
  return (
    <>
      <div className="testimonial-section2 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-title2 primary3">
                <span>-{testimonial.main_title}-</span>
                <div>
                  <h3>{testimonial.highlist_title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center position-relative">
            <Swiper
              {...testimonialSlider}
              className="swiper testimonial-slider2 swiper-fix"
            >
              <div className="swiper-wrapper">
                {
                  testimonial.testimonial_list.map((info, index) => {
                    return (
                      <SwiperSlide className="swiper-slide" key={index}>
                        <div
                          className="testimonial-single2 hover-border2 wow fadeInDown"
                          data-wow-duration="1.5s"
                          data-wow-delay=".4s"
                        >
                          <div className="quote">
                            <img
                              alt = "QuoteWhite"
                              src={QuoteWhite}
                              className="quote-icon"
                            />
                          </div>
                          <img
                            src={TestBg}
                            alt = "TestBg"
                            className="testi2-bg"
                          />
                          <div className="author">
                            <div className="author-img">
                              <img src={info.image} alt = "webco" />
                            </div>
                            <div className="author-desig">
                              <h5>{info.customer_name}</h5>
                              <p>{info.designation}</p>
                            </div>
                          </div>
                          <p className="para">
                            {info.short_description}
                          </p>
                        </div>
                      </SwiperSlide>
                    )
                  })
                }
              </div>
            </Swiper>
            <div className="slider-bottom d-flex justify-content-center align-items-center">
              <div className="swiper-pagination2 style-2 text-center" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Teastimonial;
