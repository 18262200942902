import React, { useContext, useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import { getAboutUsDetails, getTeamDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import Team from "../Teams/Team";
import AboutContent from "./AboutContent";
import Activities from "./Activities";
import Highlights from "./Highlights";

const AboutUs = () => {
  const { appState, dispatch } = useContext(AppContext);
  const { aboutUs, aboutUsLoader } = appState;
  const { ourTeam, ourTeamLoader } = appState;
  const myRef = useRef(null);

  useEffect(() => {
    getAboutUsDetails(appState, dispatch)
  }, [])

  useEffect(() => {
    getTeamDetails(appState, dispatch)
  }, [])

  return (
    <React.Fragment>
      {
        aboutUsLoader ?
          <div className='home-spinner'>
            <ClipLoader color={"#00caeb"} loading={true} size={100} />
          </div>
          :
          Object.keys(aboutUs).length &&
          <React.Fragment>
            <Breadcrumb
              pageName={aboutUs.header_services_section.title}
              pageTitle={aboutUs.header_services_section.title}
              id={`#${aboutUs.header_services_section.title}`}
              image={aboutUs.header_services_section.banner_image}
              myRef={myRef}
            />
            <div ref={myRef}>
              <AboutContent about={aboutUs} />
              <Highlights service={aboutUs.services_section} />
              <Activities activity={aboutUs.activites_section} />
            </div>
          </React.Fragment>
      }
      {
        ourTeamLoader ?
          <div className='home-spinner'>
            <ClipLoader color={"#00caeb"} loading={true} size={100} />
          </div>
          :
          Object.keys(ourTeam).length &&
          <div className="team-section2 pt-120 pb-120">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="section-title2 primary4">
                    <span>-{ourTeam.header_team_section.title}-</span>
                  </div>
                </div>
              </div>
              {
                ourTeam.team_section.map(section => {
                  return <Team team={section} />
                })
              }
            </div>
          </div>
      }
    </React.Fragment>
  );
}

export default AboutUs;