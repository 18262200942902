import React, { useContext, useEffect } from "react";
import { getRMNDataRequest } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import RMNCard from "./RMNCard";

function Rmn() {
  const { appState, dispatch } = useContext(AppContext);

  let data = {
    ClientId: 48,
    StoreId: 1,
  };

  const { RMNData, RMNLoader } = appState;

  useEffect(() => {
    getRMNDataRequest(appState, dispatch, data);
  }, []);

  return (
    <React.Fragment>
      {RMNLoader ? (
        <div className="home-spinner">
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        Object.keys(RMNData).length > 0 && (
          <React.Fragment>
            <Breadcrumb
              pageName={RMNData?.data?.header_team_section.title}
              pageTitle={RMNData?.data?.header_team_section.title}
              id={`#${RMNData?.data?.header_team_section.title}`}
              image={RMNData?.data?.header_team_section.banner_image}
              //   myRef={myRef}
            />
            <div
              className="team-section2 pt-120 pb-120"
              // ref={myRef}
            >
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="section-title2 primary4">
                      <span>-{RMNData?.data?.header_team_section.title}-</span>
                    </div>
                  </div>
                </div>
                {RMNData?.data?.section?.map((section) => {
                  return <RMNCard service={section} />;
                })}
              </div>
            </div>
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
}

export default Rmn;
