import React, { useContext, useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  BiArrowFromLeft,
  BiArrowFromRight,
  BiConversation,
} from "react-icons/bi";
import Select from "react-select";
import { AppContext } from "../Redux/ContextProvider";

import GenericEventDates from "../../validation/GenericEventDates";
import { toast } from "react-toastify";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getEventIds } from "../Redux/API";
import SubeventPopup from "../../validation/SubeventPopup";

function Guest({
  handleStep,
  data,
  guestActivityAnswers,
  setGuestActivityAnswers,
  selectedGuestQuestionList,
  setSelectedGuestQuestionList,
  selectedGuestSubEventList,
  setSelectedGuestSubEventList,
}) {
  // console.log(data.guest);
  const [value, setValue] = useState({
    guestFname: data.guestFname,
    guestLname: data.guestLname,
    guestMobile: data.guestMobile,
    guest: data.guest,
    guestEventIds: data.guestEventIds,
    guestSelectedList: data.guestSelectedList,
    guestSelectedFinalDateList: data.guestSelectedFinalDateList,
    guestSelectedDateList: data.guestSelectedDateList,
    selectedGuestDates: [],
    guest_sub_event_list:
      selectedGuestSubEventList?.length > 0 ? selectedGuestSubEventList : [],
    selectedIds: data.selectedIds,
  });
  const { appState, dispatch } = useContext(AppContext);
  const { eventDates, eventDatesLoader, eventDatesMessage } = appState;
  const [modal, setModal] = useState(true);
  const [savedGuestAns, setSavedGuestAns] = useState(guestActivityAnswers);

  //Constants for Subevent
  const [subeventlist, setSubeventlist] = useState([]);
  const [questionlist, setQuestionlist] = useState(
    selectedGuestQuestionList?.length > 0 ? selectedGuestQuestionList : []
  );
  const [subevent, setSubevent] = useState([]);
  const [radiovalue, setRadiovalue] = useState({});
  const [answer, setAnswers] = useState([]);
  const [subevtshow, setSubevtshow] = useState(false);
  const [subeventid, setSubeventid] = useState([]);

  const handleSubevtClose = () => setSubevtshow(false);
  const handleSubevtShow = () => setSubevtshow(true);

  const [error, setError] = useState({
    guestFname: "",
    guestLname: "",
    guestMobile: "",
  });
  // console.log(error);
  const [guestdateoptions, setGuestdateoptions] = useState([]);

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckBox = (e) => {
    if (e.target.checked) {
      //  setValue({
      //     ...value,
      //     [e.target.name]: e.target.checked

      // })

      let val = eventDates.data.filter(
        (i) => i.is_enabled_for_guest_label === "Yes"
      );

      let guestPreselectd = [];
      var guestId = [];
      let guestSelected = [];

      if (data.selectedGuestDates.length > 0) {
        guestPreselectd = val.filter((i) =>
          data.selectedGuestDates.includes(i.event_date)
        );
      } else {
        guestPreselectd = val;
      }

      if (data.selectedGuestDates.length > 0) {
        guestSelected = val.filter(
          (i) =>
            i.is_default_event === "1" &&
            data.selectedGuestDates.includes(i.event_date)
        );
      } else {
        guestSelected = val.filter((i) => i.is_default_event === "1");
      }

      if (guestSelected.length > 0) {
        guestSelected.map((el) => {
          return (guestId = [...guestId, el.event_id]);
        });
      }

      setValue({
        ...value,
        guestSelectedFinalDateList: guestPreselectd,
        guestEventIds: guestId,
        [e.target.name]: e.target.checked,
      });
    } else {
      setGuestActivityAnswers([]);
      setSelectedGuestQuestionList([]);
      setValue({
        ...value,
        [e.target.name]: e.target.checked,
        guestEventIds: [],
        guestFname: "",
        guestLname: "",
        guestMobile: "",
      });
    }
  };

  const [rangestate, setRangestate] = useState(data.rangestate);

  useEffect(() => {
    if (data.selectedGuestDates.length > 0) {
      setRangestate([
        {
          startDate: new Date(data.selectedGuestDates[0].split("-").join("/")),
          endDate: new Date(
            data.selectedGuestDates[data.selectedGuestDates.length - 1]
              .split("-")
              .join("/")
          ),
          key: "selection",
        },
      ]);
      setValue({ ...value, ids: [] });
    } else {
      let val = eventDates.data.filter(
        (i) => i.is_enabled_for_guest_label === "Yes"
      );
      var dateRange = [];
      val.map((el) => {
        return (dateRange = [...dateRange, el.event_date]);
      });

      setRangestate([
        {
          startDate: new Date(dateRange[0].split("-").join("/")),
          endDate: new Date(
            dateRange[dateRange.length - 1].split("-").join("/")
          ),
          key: "selection",
        },
      ]);
    }
  }, [data.selectedGuestDates]);

  // console.log('data',data.selectedFinalDateList)

  useEffect(() => {
    if (eventDatesMessage !== "")
      if (eventDatesMessage === "success") {
        if (data.selectedGuestDates.length > 0) {
          let val = eventDates.data.filter(
            (i) =>
              i.is_enabled_for_guest_label === "Yes" &&
              data.selectedGuestDates.includes(i.event_date)
          );

          if (value.guest) {
            let valdefault = val.filter((i) => i.is_default_event === "1");

            let guestId = [];

            if (valdefault.length > 0) {
              valdefault.map((el) => {
                return (guestId = [...guestId, el.event_id]);
              });

              setValue({
                ...value,
                guestEventIds: guestId,
                guestSelectedFinalDateList: val,
              });
            }
          } else {
            setValue({
              ...value,
              guestSelectedFinalDateList: val,
            });
          }
        } else {
          //  let val = eventDates.data.filter(i => i.is_enabled_for_guest_label === "Yes")
          //     let guestPreselectd = eventDates.data.filter(i => i.is_enabled_for_guest_label === "Yes" && i.is_default_event === "1")
          //     var guestId = [];
          //     guestPreselectd.map((el) => {
          //         return (
          //             guestId = [...guestId, el.event_id]
          //         )
          //     })
          //     setValue({
          //         ...value,
          //         guestSelectedFinalDateList: val,
          //         guestEventIds: guestId,
          //     })
        }
      } else {
        toast.error(eventDatesMessage);
      }
  }, [eventDatesMessage]);

  const handleDate = (item) => {
    if (
      format(item.selection.endDate, "yyyy-MM-dd") >
      format(item.selection.startDate, "yyyy-MM-dd")
    ) {
      let info = {
        ClientId: 48,
        email_id: data.email,
        registration_type_id: data.selectedType,
        timezone: "Asia/kolkata",
        registration_sub_type_id: data.selectedSubType,
        start_date: format(item.selection.startDate, "yyyy-MM-dd"),
        end_date: format(item.selection.endDate, "yyyy-MM-dd"),
        allow_for_first_time_sponsors: data.allow_for_first_time_sponsors,
      };
      dispatch(getEventIds(appState, dispatch, info));
    }
    // setRangestate([item.selection])
    setRangestate([
      {
        startDate: new Date(item.selection.startDate),
        endDate: new Date(item.selection.endDate),
        key: "selection",
      },
    ]);
  };

  //Mutli Select callback function
  const handleMutliInput = (e, arr) => {
    let val = e.map((info) => info.value);
    let array = arr
      .filter(
        (info) =>
          val.includes(info.event_date) &&
          info.is_enabled_for_guest_label === "Yes"
      )
      .map((val) => val);
    setValue({
      ...value,
      guestSelectedDateList: e,
      guestSelectedFinalDateList: array,
    });
  };

  useEffect(() => {
    if (data.selectedGuestDates.length > 0) {
      let uniqueChars = data.selectedGuestDates.filter((c, index) => {
        return data.selectedGuestDates.indexOf(c) === index;
      });
      let arr = uniqueChars.map((val) => {
        return { value: val, label: val };
      });
      setGuestdateoptions(arr);
    }
  }, []);

  function findCommonElements2(arr1, arr2) {
    // Create an empty object
    let obj = {};

    // Loop through the first array
    for (let i = 0; i < arr1.length; i++) {
      // Check if element from first array
      // already exist in object or not
      if (!obj[arr1[i]]) {
        // If it doesn't exist assign the
        // properties equals to the
        // elements in the array
        let element = arr1[i];
        obj[element] = true;
      }
    }

    // Loop through the second array
    for (let j = 0; j < arr2.length; j++) {
      // Check elements from second array exist
      // in the created object or not
      if (obj[arr2[j]]) {
        return true;
      }
    }
    return false;
  }

  const submitInfo = (action, e) => {
    if (action === "next") {
      let flag = true;
      let err = { ...error };
      if (value.guest) {
        if (value.guestFname === "") {
          err = {
            ...err,
            guestFname: "Please enter first name.",
          };
          flag = false;
          window.scrollTo(0, 0);
        }
        if (value.guestLname === "") {
          err = {
            ...err,
            guestLname: "Please enter last name.",
          };
          flag = false;
          window.scrollTo(500, 500);
        }
      }

      if (flag) {
        if (subeventid.length > 0 && value.guest_sub_event_list.length === 0) {
          toast.error("Please select at least one sub event to proceed.");
        } else {
          if (value.guestSelectedFinalDateList.length > 0) {
            handleStep(value, action, "guest");
          } else {
            toast.error("Please select at least one event to proceed.");
          }
        }
        // handleStep(value, action, "guest");
      } else setError(err);
    } else {
      handleStep(value, action, "guest");
    }
  };

  const handleEvents = (id) => {
    let array = value.guestEventIds;

    if (array.includes(id)) {
      const index = array.indexOf(id);

      array.splice(index, 1);

      setValue({
        ...value,
        guestEventIds: array,
      });

      if (subeventid.length > 0) {
        setSubeventid([]);
      }

      if (value.guest_sub_event_list.length > 0) {
        setValue({
          ...value,
          guest_sub_event_list: [value.guest_sub_event_list, []],
        });
        setAnswers([]);
      }
    } else {
      const filterWholesalerdata = data.selectedFinalDateList.filter(
        (el) => el.event_id === id
      );

      const allGuestActivityEventIds = value?.guestSelectedFinalDateList
        ?.filter((each) => each.allow_activity_event === "1")
        .map((el) => el.event_id);

      const condition1 = findCommonElements2(
        value?.guestEventIds,
        allGuestActivityEventIds
      );

      const condition2 = allGuestActivityEventIds?.includes(id);

      if (checkSubevents(filterWholesalerdata)) {
        setSubevtshow(true);
        setSubeventid(filterWholesalerdata[0].event_id);
      } else {
        setSubevtshow(false);
      }

      if (condition1 && condition2) {
        toast.error("You can select Only One Activity Event in Guest Events.");
        setModal(false);
      } else {
        setModal(true);
        setValue({
          ...value,
          guestEventIds: [...value.guestEventIds, id],
        });
      }
    }
  };

  //Check Subevents
  const checkSubevents = (subevt) => {
    if (subevt[0].sub_event_list.length > 0) {
      setSubeventlist(subevt[0].sub_event_list);
      return true;
    }
  };

  //Handle selectSubevt
  const selectSubevt = (e) => {
    const selectedsubevt = subeventlist.filter(
      (x) => x.sub_event_id === e.target.value
    );

    if (selectedsubevt[0].question_list.length > 0) {
      setQuestionlist(selectedsubevt[0].question_list);
      setSubevent(selectedsubevt);
    } else {
      // setSubevent(selectedsubevt[0])
      setQuestionlist([]);

      const event = [];
      selectedsubevt.map((val, idx) => {
        event.push({
          sub_event_id: val.sub_event_id,
          event_id: subeventid,
          title: val.title,
          status: val.status,
          order_by: val.order_by,
          not_allow_event_id: val.not_allow_event_id,
          status_label: val.status_label,
          question_list: [],
        });
      });

      setValue({
        ...value,
        guest_sub_event_list: event,
      });
    }
  };
  const handleAnswers = (e, sub_event_question_id, type) => {
    setRadiovalue({
      ...radiovalue,
      [e.target.name]: e.target.value,
    });

    const question = subevent[0].question_list.filter(
      (el) => el.sub_event_question_id === sub_event_question_id
    );
    const optionvalue = question[0].values.filter(
      (x) => x.sub_event_question_value_id === e.target.value
    );

    const ans = [];

    if (type === "radio") {
      question.map((val, idx) => {
        ans.push({
          sub_event_question_id: val.sub_event_question_id,
          sub_event_id: val.sub_event_id,
          type: val.type,
          title: val.title,
          answer_type: val.answer_type,
          answer: "",
          values: optionvalue,
        });
      });
    } else {
      question.map((val, idx) => {
        ans.push({
          sub_event_question_id: val.sub_event_question_id,
          sub_event_id: val.sub_event_id,
          type: val.type,
          title: val.title,
          answer_type: val.answer_type,
          answer: e.target.value,
          values: [],
        });
      });
    }

    setAnswers({
      ...answer,
      [e.target.name]: ans,
    });
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-lg-8">
        <div className="mb-10">
          <h2>Guest Information:</h2>
        </div>
        <Form>
          <div className="p-17 mb-30">
            <Row className="row g-3">
              <Col xs={12}>
                <Form.Check
                  type="checkbox"
                  label="Are you coming with a guest?"
                  id="custom-switch"
                  name="guest"
                  checked={value.guest}
                  onChange={(e) => handleCheckBox(e)}
                />
              </Col>
            </Row>
          </div>
        </Form>
        {value.guest && (
          <React.Fragment>
            <form>
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="guestFname"
                      placeholder="First Name"
                      // onChange={handleChange}
                      onChange={(e) => {
                        setError({
                          ...error,
                          guestFname: "",
                        });
                        handleChange(e);
                      }}
                      value={value.guestFname || ""}
                      className={`input ${error.guestFname && "is-danger"}`}
                    />
                    {error.guestFname && (
                      <p
                        className="help is-danger"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          padding: "2px",
                        }}
                      >
                        {error.guestFname}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="guestLname"
                      placeholder="Last Name"
                      // onChange={handleChange}
                      onChange={(e) => {
                        setError({
                          ...error,
                          guestLname: "",
                        });
                        handleChange(e);
                      }}
                      value={value.guestLname || ""}
                    />
                    {error.guestLname && (
                      <p
                        className="help is-danger"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          padding: "2px",
                        }}
                      >
                        {error.guestLname}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="guestMobile"
                      placeholder="Mobile (Optional)"
                      maxLength={10}
                      onChange={(e) => {
                        const reg = /^\d{0,10}$/;
                        if (e.target.value === "" || reg.test(e.target.value)) {
                          setError({
                            ...error,
                            guestMobile: "",
                          });
                          handleChange(e);
                        } else {
                          setError({
                            ...error,
                            guestMobile: "Please enter a valid Mobile Number",
                          });
                        }
                      }}
                      value={value.guestMobile}
                    />
                    {error.guestMobile && (
                      <p
                        className="help is-danger"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          padding: "2px",
                        }}
                      >
                        {error.guestMobile}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <Form className="mt-20">
              <div className="p-17">
                <Row>
                  <Col xs={12}>
                    <Form.Group className="mt-16">
                      {/* <Form.Label>Event Dates:</Form.Label>  */}
                      {/* <Select
                                                isMulti
                                                //options={data.options}
                                                options={guestdateoptions}
                                                className={"react-select-container"}
                                                value={value.guestSelectedDateList}
                                                onChange={(e) => handleMutliInput(e, data.eventsList)}
                                            /> */}

                      {/* {value.guestSelectedFinalDateList.length > 0 ? (
                        <DateRange
                          onChange={(item) => handleDate(item)}
                          editableDateInputs={true}
                          moveRangeOnFirstSelection={false}
                          ranges={rangestate}
                          months={1}
                          direction="horizontal"
                          className="calendarElement"
                          //minDate={data.selectedGuestDates.length > 1?new Date(data.selectedGuestDates[0].split("-").join("/")):new Date("2022/11/10")}
                          //maxDate={data.selectedGuestDates.length > 0?new Date(data.selectedGuestDates[data.selectedGuestDates.length - 1].split("-").join("/")):new Date("2022/11/10")}
                        />
                      ) : (
                        ""
                      )} */}
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="row g-4 mt-20">
                <div className="col-lg-12">
                  <div className="form-inner">
                    <div className="mb-10">
                      <h2>Select Events:</h2>
                    </div>
                    <GenericEventDates
                      component={true}
                      selectedEvent={value.guestSelectedFinalDateList}
                      ids={value.guestEventIds}
                      handleEvents={handleEvents}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </React.Fragment>
        )}
        <div className="arrow-container">
          <div
            className="col-lg-12 text-center mt-20 arrow"
            onClick={() => submitInfo("previous")}
          >
            <BiArrowFromRight size={30} color={"rgba(0, 202, 235, 0.8)"} />
          </div>
          <div
            className="col-lg-12 text-center mt-20 arrow"
            onClick={() => {
              setGuestActivityAnswers(savedGuestAns);
              setSelectedGuestQuestionList(questionlist);
              setSelectedGuestSubEventList(value.guest_sub_event_list);
              submitInfo("next");
            }}
          >
            <BiArrowFromLeft size={30} color={"rgba(0, 202, 235, 0.8)"} />
          </div>
        </div>

        {modal && (
          <SubeventPopup
            data={data}
            handleClose={handleSubevtClose}
            show={subevtshow}
            subeventlist={subeventlist}
            selectSubevt={selectSubevt}
            questionlist={questionlist}
            handleAnswers={handleAnswers}
            radiovalue={radiovalue}
            answer={answer}
            savedAns={savedGuestAns}
            setSavedAns={setSavedGuestAns}
            subevent={subevent}
            guest
            guestValue={value}
            subeventid={subeventid}
            value={value}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  );
}

export default Guest;
