import React, { useContext, useState, useEffect } from "react";
import { BiArrowFromLeft } from "react-icons/bi";
import axios from "axios";
import { getStates } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import Spinner from "react-bootstrap/Spinner";

function BasicInfo({ handleStep, data }) {
  const { appState, dispatch } = useContext(AppContext);
  const { states, stateEventLoader } = appState;

  const [value, setValue] = useState({
    fname: data.fname,
    lname: data.lname,
    company: data.company,
    address: data.address,
    mobile: data.mobile,
    logo: data.logo,
    city: data.city,
    zip_code: data.zip_code,
    state: data.state,
  });
  const [error, setError] = useState({
    fname: "",
    lname: "",
    company: "",
    address: "",
    mobile: "",
    logo: "",
    city: "",
    zip_code: "",
    state: "",
  });

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };
  const [loader, setLoader] = useState(false);

  const onFileChange = (event) => {
    let images = event.target.files[0];
    let err = { ...error };

    if (!images.name.match(/\.(jpg|gif|jpeg|png|PNG|JPEG|JPG)$/)) {
      //   toast.error("Please Select a Valid Image!");

      err = {
        ...err,
        logo: "Please Select a Valid Image!.",
      };
      setError(err);

      return false;
    } else {
      err = {
        ...err,
        logo: "",
      };
      setError(err);

      setLoader(!loader);

      const dataArray = new FormData();
      dataArray.append("logo", images);
      let requestURL = process.env.REACT_APP_URL + "UploadImageFile";
      axios
        .post(requestURL, dataArray, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            // eslint-disable-next-line no-useless-computed-key
            setValue({ ...value, ["logo"]: res.data.data.event_logo });
            setLoader(false);
          } else {
            //setTimeout(() => { clearField() }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getStates(appState, dispatch);
  }, []);

  const submitInfo = () => {
    const {
      fname,
      lname,
      company,
      address,
      mobile,
      logo,
      city,
      zip_code,
      state,
    } = value;
    let flag = true;
    var pattern = new RegExp(/^[0-9\b]+$/);
    let err = { ...error };
    if (fname === "") {
      err = {
        ...err,
        fname: "Please enter first name.",
      };
      flag = false;
    }
    if (lname === "") {
      err = {
        ...err,
        lname: "Please enter last name.",
      };
      flag = false;
    }

    if (data.type[0].registration_type_id !== "3") {
      if (company === "") {
        err = {
          ...err,
          company: "Please enter company.",
        };
        flag = false;
      }
    }
    if (address === "") {
      err = {
        ...err,
        address: "Please enter address.",
      };
      flag = false;
    }
    if (city === "") {
      err = {
        ...err,
        city: "Please enter city.",
      };
      flag = false;
    }
    if (zip_code === "") {
      err = {
        ...err,
        zip_code: "Please enter zip.",
      };
      flag = false;
    }
    if (document.querySelector("#zip_code").value.trim().length > 5) {
      err = {
        ...err,
        zip_code: "Zip should not be more than length 5.",
      };
      flag = false;
    }
    if (!pattern.test(document.querySelector("#zip_code").value)) {
      err = {
        ...err,
        zip_code: "Please enter valid zip code.",
      };
      flag = false;
    }

    if (state === "") {
      err = {
        ...err,
        state: "Please enter state.",
      };
      flag = false;
    }
    // if (mobile === "") {
    //   err = {
    //     ...err,
    //     mobile: "Please enter mobile number.",
    //   };
    //   flag = false;
    // }

    // if (!pattern.test(document.querySelector("#mobile").value)) {
    //   err = {
    //     ...err,
    //     mobile: "Please enter valid mobile number.",
    //   };
    //   flag = false;
    // }

    // if (document.querySelector('#logo').value.trim().length <= 0) {
    //     err = {
    //         ...err,
    //         logo: "Please select logo."
    //     }
    //     flag = false

    // }
    // if (logo === "") {
    //     err = {
    //         ...err,
    //         logo: "Please select valid image."
    //     }
    //     flag = false

    // }

    if (flag) handleStep(value, "next", "basic");
    else setError(err);
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-lg-8">
        <div className="mb-10">
          <h2>Personal Information:</h2>
        </div>
        <form>
          <div className="row g-4">
            <div className="col-lg-6">
              <div className="form-inner">
                <input
                  type="text"
                  name="fname"
                  placeholder="First Name"
                  onChange={(e) => {
                    setError({
                      ...error,
                      fname: "",
                    });
                    handleChange(e);
                  }}
                  value={value.fname || ""}
                  className={`input ${error.fname && "is-danger"}`}
                />
                {error.fname && (
                  <p
                    className="help is-danger"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {error.fname}
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-inner">
                <input
                  type="text"
                  name="lname"
                  placeholder="Last Name"
                  onChange={(e) => {
                    setError({
                      ...error,
                      lname: "",
                    });
                    handleChange(e);
                  }}
                  value={value.lname || ""}
                />
                {error.lname && (
                  <p
                    className="help is-danger"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {error.lname}
                  </p>
                )}
              </div>
            </div>
            {data.type[0].registration_type_id === "3" ? (
              ""
            ) : (
              <div className="col-lg-6">
                <div className="form-inner">
                  <input
                    type="text"
                    name="company"
                    placeholder="Company"
                    onChange={(e) => {
                      setError({
                        ...error,
                        company: "",
                      });
                      handleChange(e);
                    }}
                    value={value.company || ""}
                  />
                  {error.company && (
                    <p
                      className="help is-danger"
                      style={{
                        color: "red",
                        fontSize: "12px",
                        padding: "2px",
                      }}
                    >
                      {error.company}
                    </p>
                  )}
                </div>
              </div>
            )}
            <div className="col-lg-6">
              <div className="form-inner">
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  onChange={(e) => {
                    setError({
                      ...error,
                      address: "",
                    });
                    handleChange(e);
                  }}
                  value={value.address || ""}
                />
                {error.address && (
                  <p
                    className="help is-danger"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {error.address}
                  </p>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-inner">
                <select
                  name="state"
                  className="select_questions"
                  style={{
                    border: "1px solid #dddddd",
                    borderRadius: "5px",
                    padding: "15px 15px",
                    width: "100%",
                    transition: "all 0.5s ease",
                  }}
                  onChange={(e) => {
                    setError({
                      ...error,
                      state: "",
                    });
                    handleChange(e);
                  }}
                >
                  <option
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,

                      fontFamily: "Poppins",
                    }}
                  >
                    Select State
                  </option>
                  {Object.keys(states).length > 0
                    ? states.map((val, index) => {
                        return (
                          <option value={val.state_name}>
                            {val.state_name}
                          </option>
                        );
                      })
                    : ""}
                </select>
                {error.state && (
                  <p
                    className="help is-danger"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {error.state}
                  </p>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-inner">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  onChange={(e) => {
                    setError({
                      ...error,
                      city: "",
                    });
                    handleChange(e);
                  }}
                  value={value.city || ""}
                />
                {error.city && (
                  <p
                    className="help is-danger"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {error.city}
                  </p>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-inner">
                <input
                  type="text"
                  name="zip_code"
                  id="zip_code"
                  placeholder="Zip"
                  maxLength="5"
                  onChange={(e) => {
                    setError({
                      ...error,
                      zip_code: "",
                    });
                    handleChange(e);
                  }}
                  value={value.zip_code || ""}
                />
                {error.zip_code && (
                  <p
                    className="help is-danger"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {error.zip_code}
                  </p>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-inner">
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile (Optional)"
                  maxLength="10"
                  id="mobile"
                  onChange={(e) => {
                    const reg = /^\d{0,10}$/;
                    if (e.target.value === "" || reg.test(e.target.value)) {
                      setError({
                        ...error,
                        mobile: "",
                      });
                      handleChange(e);
                    }
                    // else {
                    //   setError({
                    //     ...error,
                    //     mobile: "Please Enter a valid mobile Number",
                    //   });
                    // }
                  }}
                  value={value.mobile || ""}
                  pattern="/^\d{0,10}$/"
                />
                {error.mobile && (
                  <p
                    className="help is-danger"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {error.mobile}
                  </p>
                )}
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-inner">
                <label>Upload Company Logo:</label>
                <input
                  type="file"
                  name="logo"
                  id="logo"
                  onChange={(e) => onFileChange(e)}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
                {loader ? <Spinner animation="border" variant="primary" /> : ""}
                {error.logo && (
                  <p
                    className="help is-danger"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      padding: "2px",
                    }}
                  >
                    {error.logo}
                  </p>
                )}
              </div>
            </div>
            <div className="arrow-container">
              <div
                className="col-lg-12 text-center mt-20 arrow"
                onClick={submitInfo}
              >
                <BiArrowFromLeft size={30} color={"rgba(0, 202, 235, 0.8)"} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BasicInfo;
