import React, { useContext, useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import Blog from "../Home/Blog";
import Breadcrumb from "../Generic/Breadcrumb";
import { getBlogDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";

const Blogs = () => {
  const { appState, dispatch } = useContext(AppContext);
  const { blog, blogLoader } = appState;
  const myRef = useRef(null);

  useEffect(() => {
    getBlogDetails(appState, dispatch)
  }, [])

  return (
    <React.Fragment>
      {
        blogLoader ?
          <div className='home-spinner'>
            <ClipLoader color={"#00caeb"} loading={true} size={100} />
          </div>
          :
          Object.keys(blog).length &&
          <React.Fragment>
            <Breadcrumb
                pageName={blog.header_services_section.title}
                pageTitle={blog.header_services_section.title}
                id={`#${blog.header_services_section.title}`}
                image={blog.header_services_section.banner_image}
                myRef={myRef}
              />
            <div ref={myRef}>
              <Blog blog={blog.blogs_section} />
            </div>
          </React.Fragment>
      }
    </React.Fragment>
  );
}

export default Blogs;