import React, { useEffect, useState } from 'react';
import { BiArrowFromBottom } from "react-icons/bi";

export const ScrollToTopIcon = () => {
    const [visible, setVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    const toggleVisibility = () => {
        if (window.scrollY > 150)
            setVisible(true)
        else setVisible(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility)
    }, [])

    return (
        <div className='scroll-to-top'>
            {
                visible &&
                <div className='button-container' onClick={scrollToTop}>
                    <BiArrowFromBottom
                        size={30}
                        color={"white"}
                    />
                </div>
            }
        </div>
    )
}