import React, { useState } from "react";

function RMNCard({ service, title }) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImage(null);
  };

  return (
    <React.Fragment>
      {Object.keys(service).length && (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-title2 primary4">
                <div>
                  <h3>{service?.highlist_title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row  rmncard justify-content-center g-4 col-12">
            {service.services_list &&
              service.services_list.length &&
              service.services_list.map((member) => {
                const isEmptyTargetUrl =
                  member.target_url === "" ? true : false;
                return (
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="single-team2 rmn-img wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.2s"
                    >
                      <div className="team-image">
                        <div onClick={() => openModal(member.icon_image)}>
                          <img src={member.icon_image} alt="member" />
                        </div>
                        <div className=" gap-3">
                          <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                            {member.instagram_url && (
                              <li>
                                <a
                                  href={`${member.instagram_url}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="bx bxl-instagram" />
                                </a>
                              </li>
                            )}
                            {member.facebook_url && (
                              <li>
                                <a
                                  href={`${member.facebook_url}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="bx bxl-facebook" />
                                </a>
                              </li>
                            )}
                            {member.twitter_url && (
                              <li>
                                <a
                                  href={`${member.twitter_url}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="bx bxl-twitter" />
                                </a>
                              </li>
                            )}
                            {member.pinterest_url && (
                              <li>
                                <a
                                  href={`${member.pinterest_url}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="bx bxl-pinterest-alt" />
                                </a>
                              </li>
                            )}

                            {member.linkedin_url && (
                              <li>
                                <a
                                  href={`${member.linkedin_url}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <i className="bx bxl-linkedin" />
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="team-content">
                        <h4 className="name">{member.title}</h4>
                        <p className="designation">
                          {member.services_sub_title}
                        </p>
                        <p className="designation">
                          {member.sub_section_title2
                            ? member.sub_section_title2
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12 text-center mt-5">
                      <a
                        href={!isEmptyTargetUrl && member.target_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          className="eg-btn btn--submit"
                          disabled={isEmptyTargetUrl}
                        >
                          Click Here To Get Started!
                        </button>
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
          {isOpen && (
            <div className="modal-overlay" onClick={closeModal}>
              <div className="modal-content">
                <span className="close-modal" onClick={closeModal}>
                  &times;
                </span>
                <img
                  src={currentImage}
                  alt="Modal View"
                  className="modal-image"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default RMNCard;
