export const STORE_VALUES = "STORE_VALUES";
export const GET_CONTACT_DETAILS = "GET_CONTACT_DETAILS";
export const GET_ABOUT_US_DETAILS = "GET_ABOUT_US_DETAILS";
export const GET_TEAM_DETAILS = "GET_TEAM_DETAILS";
export const GET_CONFERENCE_DETAILS = "GET_CONFERENCE_DETAILS";
export const GET_HISTORY_DETAILS = "GET_HISTORY_DETAILS";
export const GET_BLOG_DETAILS = "GET_BLOG_DETAILS";
export const GET_COMMON_DETAILS = "GET_COMMON_DETAILS";
export const GET_HOME_DETAILS = "GET_HOME_DETAILS";
export const GET_SALUTE_DETAILS = "GET_SALUTE_DETAILS";
export const GET_PARTNER_DETAILS = "GET_PARTNER_DETAILS";
export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const GET_EVENT_IDS = "GET_EVENT_IDS";
export const REGISTER_EVENT = "REGISTER_EVENT";
export const GET_STATE_DETAILS = "GET_STATE_DETAILS";
export const SAVE_INVITATION_DETAILS = "SAVE_INVITATION_DETAILS";
export const GET_LOGGED_IN = "GET_LOGGED_IN";
export const GET_EVENTS_LIST = "GET_EVENTS_LIST";
export const GET_RMN_DATA = "GET_RMN_DATA";
