import React, { useContext, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import About from './About';
import Banner from './Banner';
import Blog from './Blog';
import JoinOurTeam from './JoinOurTeam';
import OurPartner from './OurPartners';
import Portfolio from './Portfolio';
import { getHomeDetails } from '../Redux/API';
import { AppContext } from '../Redux/ContextProvider';
import Testimonial from './Testimonial'

const Home = () => {
    const { appState, dispatch } = useContext(AppContext);
    const { common, home, homeLoader } = appState;

    useEffect(() => {
        getHomeDetails(appState, dispatch)
    }, [])

    return (
        <React.Fragment>
            {
                homeLoader ?
                    <div className='home-spinner'>
                        <ClipLoader color={"#00caeb"} loading={true} size={100} />
                    </div>
                    :
                    Object.keys(home).length &&
                    <React.Fragment>
                        <Banner
                            banner={home.banner_section}
                            social={common.common_section}
                        />
                        <About about={home.about_section} />
                        <Portfolio portfolio={home.portfolio_section} />
                        <Testimonial testimonial={home.testimonial_section} />
                        <Blog blog={home.blogs_section} />
                        {/* <JoinOurTeam /> */}
                        {/* <OurPartner partner={home.partners_section} /> */}
                    </React.Fragment>
            }
        </React.Fragment>
    )
}

export default Home