import React, { useContext, useRef } from "react";
import { AppContext } from "../Redux/ContextProvider";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";

function FloorPlan() {
  const { appState, dispatch } = useContext(AppContext);
  const { home, homeLoader } = appState;
  const myRef = useRef(null);
  console.log("image has to display", home.floorplan_image);
  return (
    <React.Fragment>
      {homeLoader ? (
        <div className="home-spinner">
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        <React.Fragment>
          <Breadcrumb
            pageName="Floor Plan"
            pageTitle="Floor Plan"
            id=""
            image=""
            myRef={myRef}
          />
          <div ref={myRef}>
            <div className="service2-section pt-120 pb-120">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="section-title2 primary4">
                      <span>-Floor Plan-</span>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center g-4">
                  <img src={home.floorplan_image} alt="Floorplan" />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default FloorPlan;
