import * as Constants from "./Action";

export const reducer = (state, action) => {
  const { payload, type, loader, key, message, statusmessage } = action;
  switch (type) {
    case Constants.STORE_VALUES: {
      return {
        ...state,
        storedValues: {
          ...state.storedValues,
          [key]: payload,
        },
      };
    }
    case Constants.GET_CONTACT_DETAILS: {
      return {
        ...state,
        contact: payload,
        contactLoader: loader,
        contactMessage: message,
      };
    }

    case Constants.GET_STATE_DETAILS: {
      return {
        ...state,
        states: payload,
        stateEventLoader: loader,
        sateEventMessage: message,
      };
    }
    case Constants.GET_ABOUT_US_DETAILS: {
      return {
        ...state,
        aboutUs: payload,
        aboutUsLoader: loader,
        aboutUsMessage: message,
      };
    }
    case Constants.GET_TEAM_DETAILS: {
      return {
        ...state,
        ourTeam: payload,
        ourTeamLoader: loader,
        ourTeamMessage: message,
      };
    }
    case Constants.GET_CONFERENCE_DETAILS: {
      return {
        ...state,
        conference: payload,
        conferenceLoader: loader,
        conferenceMessage: message,
      };
    }
    case Constants.GET_HISTORY_DETAILS: {
      return {
        ...state,
        history: payload,
        historyLoader: loader,
        historyMessage: message,
      };
    }
    case Constants.GET_BLOG_DETAILS: {
      return {
        ...state,
        blog: payload,
        blogLoader: loader,
        blogMessage: message,
      };
    }
    case Constants.GET_COMMON_DETAILS: {
      return {
        ...state,
        common: payload,
        commonLoader: loader,
        commonMessage: message,
      };
    }
    case Constants.GET_HOME_DETAILS: {
      return {
        ...state,
        home: payload,
        homeLoader: loader,
        homeMessage: message,
      };
    }
    case Constants.GET_SALUTE_DETAILS: {
      return {
        ...state,
        salute: payload,
        saluteLoader: loader,
        saluteMessage: message,
      };
    }

    case Constants.GET_PARTNER_DETAILS: {
      return {
        ...state,
        partner: payload,
        partnerLoader: loader,
        partnerMessage: message,
      };
    }

    case Constants.EMAIL_VERIFICATION: {
      return {
        ...state,
        emailVerification: payload,
        emailVerificationMessage: message,
        emailVerificationLoader: loader,
        emailVerificationStatusMessage: statusmessage,
      };
    }

    case Constants.GET_EVENT_IDS: {
      return {
        ...state,
        eventDates: payload,
        eventDatesMessage: message,
        eventDatesLoader: loader,
      };
    }
    case Constants.REGISTER_EVENT: {
      return {
        ...state,
        registerEvent: payload,
        registerEventLoader: loader,
        registerEventMessage: message,
      };
    }
    case Constants.SAVE_INVITATION_DETAILS: {
      return {
        ...state,
        submitInvitationReqEvent: payload,
        submitInvitationReqEventLoader: loader,
        submitInvitationReqEventMessage: message,
      };
    }
    case Constants.GET_LOGGED_IN: {
      return {
        ...state,
        loginInfo: payload,
        loginLoader: loader,
        loginMessage: message,
      };
    }

    case Constants.GET_EVENTS_LIST: {
      return {
        ...state,
        eventsListInfo: payload,
        eventsListLoader: loader,
        eventsListMessage: message,
      };
    }

    case Constants.GET_RMN_DATA: {
      return {
        ...state,
        RMNData: payload,
        RMNLoader: loader,
        RMNMessage: message,
      };
    }

    default:
      return state;
  }
};
