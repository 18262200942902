export default function validate(values) {

  let errors = {};

  if (!values.fname) {
    errors.fname = 'Name can\'t be blank';
  }
  else if (!values.email) {
    errors.email = 'Email can\'t be blank';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  else if (!values.ph_no) {
    errors.ph_no = 'Phone Number can\'t be blank';
  }
  else if (!values.subject) {
    errors.subject = 'Subject can\'t be blank';
  }
  else if (!values.contact_question_option) {
    errors.contact_question_option = 'Select one option';
  }
  else if (!values.referred_by) {
    errors.referred_by = 'Where did you hear about us can\'t be blank';
  }

  return errors;
};