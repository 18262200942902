import React, { useContext, useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import { getSaluteDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";

const Salute = () => {
    const { appState, dispatch } = useContext(AppContext);
    const { salute, saluteLoader } = appState;
    const myRef = useRef(null);

    useEffect(() => {
        getSaluteDetails(appState, dispatch)
    }, [])

    return (
        <React.Fragment>
            {
                saluteLoader ?
                    <div className='home-spinner'>
                        <ClipLoader color={"#00caeb"} loading={true} size={100} />
                    </div>
                    :
                    Object.keys(salute).length &&
                    <React.Fragment>
                        <Breadcrumb
                            pageName={salute.header_contact_section.title}
                            pageTitle={salute.header_contact_section.title}
                            id={`#${salute.header_contact_section.title}`}
                            image = {salute.header_contact_section.banner_image}
                            myRef={myRef}
                        />
                        <div ref={myRef}>
                            {
                                salute.salutes_section && salute.salutes_section.length &&
                                salute.salutes_section.map(section => {
                                    return (
                                        section.services_list.length &&
                                        <React.Fragment>
                                            {
                                                <div className="portfolio-section pt-120 pb-120" id="project">
                                                    <div className="container">
                                                        <div className="row justify-content-center">
                                                            <div className="col-md-6">
                                                                <div className="section-title primary5">
                                                                    <span>-{section.main_title}-</span>
                                                                    <h3>{section.highlist_title}</h3>
                                                                    <p className="para">
                                                                        {section.sub_title}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row position-relative justify-content-center g-4">
                                                            {section.services_list.map((item) => {
                                                                return (
                                                                    <div className="col-lg-4 col-md-6 col-sm-10" key={item.id}>
                                                                        <div
                                                                            className="portfolio-item1 wow fadeInDown"
                                                                            data-wow-duration="1.5s"
                                                                            data-wow-delay="0.2s"
                                                                        >
                                                                            <div className="portfolio-img">
                                                                                {
                                                                                    item.icon_image &&
                                                                                    <img alt="portfolio-img" src={item.icon_image} />
                                                                                }
                                                                            </div>
                                                                            <div className="portfolio-content">
                                                                                <span>{section.highlist_title}</span>
                                                                                <h4>
                                                                                    <p>{item.title}</p>
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>
    );
}

export default Salute;
