import React, { useContext, useEffect, useState } from "react";
import { BiArrowFromLeft, BiArrowFromRight } from "react-icons/bi";
import { Col, Form, Row } from "react-bootstrap";
import { AppContext } from "../Redux/ContextProvider";
import { getEventIds } from "../Redux/API";
import { ClipLoader } from "react-spinners";
//import Select from 'react-select'
import { toast, ToastContainer } from "react-toastify";
import GenericEventDates from "../../validation/GenericEventDates";
import Modal from "../../validation/Modal";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import SubeventPopup from "../../validation/SubeventPopup";

function EventDates({
  data,
  handleStep,
  allValues,
  setAllValues,
  wholesaleListSave,
  activityAnswers,
  setActivityAnswers,
  setSelectedQuestionList,
  selectedQuestionList,
  setSelectedSubEventList,
  selectedSubEventList,
  setGuestActivityAnswers,
  setSelectedGuestQuestionList,
}) {
  const { appState, dispatch } = useContext(AppContext);
  const { eventDates, eventDatesLoader, eventDatesMessage } = appState;
  const [modal, setModal] = useState(true);
  const [value, setValue] = useState({
    eventDateList: [],
    selectedFinalDateList: data.selectedFinalDateList,
    eventsList: [],
    selectedType: "",
    selectedSubType: "",
    selectedDateList: data.selectedDateList,
    options: data.options,
    ids: data.tempIds,
    wholesalerIds: [],
    timeslot: "",
    selectwholesalerlist: [],
    wholesalerevtid: [],
    selectedGuestDates: data.selectedGuestDates,
    default_ids: [],
    sub_event_list:
      selectedSubEventList?.length > 0 ? selectedSubEventList : [],
    allow_for_first_time_sponsors: data.allow_for_first_time_sponsors,
  });

  // console.log(allValues);
  const [timeslots, setTimeslots] = useState([]);
  const [wholesalerevtid, setWholesalerevtid] = useState([]);
  const [show, setShow] = useState(false);
  const [subevtshow, setSubevtshow] = useState(false);
  const [savedAns, setSavedAns] = useState(activityAnswers);

  //Constants for Subevent
  const [subeventlist, setSubeventlist] = useState([]);
  const [questionlist, setQuestionlist] = useState(
    selectedQuestionList?.length > 0 ? selectedQuestionList : []
  );
  const [subevent, setSubevent] = useState([]);
  const [subeventid, setSubeventid] = useState([]);

  const [radiovalue, setRadiovalue] = useState({});
  const [answer, setAnswers] = useState([]);

  //Constants for Wholesaler
  const [timeslot, setTimeslot] = useState([]);
  const [wholesaler, setWholesaler] = useState([]);
  // const [allValues, setAllValues] = useState([]);
  const [disable, setDisable] = useState(false);
  const [options, setOptions] = useState([]);
  const [allwholesaler, setAllwholesaler] = useState([]);

  //range
  const [rangestate, setRangestate] = useState(data.rangestate);

  //API call Component Did Mount
  const loadEventsList = () => {
    let info = {
      ClientId: 48,
      email_id: data.email,
      registration_type_id: data.selectedType,
      timezone: "Asia/kolkata",
      registration_sub_type_id: data.selectedSubType,
      allow_for_first_time_sponsors: data.allow_for_first_time_sponsors,
    };
    dispatch(getEventIds(appState, dispatch, info));
  };

  useEffect(() => {
    loadEventsList();
  }, []);

  //Handling Page after API response
  useEffect(() => {
    if (eventDatesMessage !== "")
      if (eventDatesMessage === "success") {
        let val = eventDates.data
          .filter((i) => i.is_default_event === "1")
          .map((information) => information.event_id);

        setValue({
          ...value,
          eventDateList: eventDates.event_dates,
          eventsList: eventDates.data,
          selectedFinalDateList: eventDates.data,
          default_ids: val,
        });
      } else {
        toast.error(eventDatesMessage);
      }
  }, [eventDatesMessage]);

  //Creating List for options for Multi Select component
  useEffect(() => {
    if (value.eventDateList.length) {
      let arr = value.eventDateList.map((val) => {
        return { value: val, label: val };
      });
      setValue({
        ...value,
        options: arr,
      });
    }
  }, [value.eventDateList]);

  //Mutli Select callback function
  // const handleMutliInput = (e, a) => {
  //     let val = e.map(info => info.value)
  //     let array = a.filter(info => val.includes(info.event_date)).map(val => val);
  //     setValue({
  //         ...value,
  //         selectedFinalDateList: array,
  //         selectedDateList: e
  //     })
  // }

  useEffect(() => {
    if (value.options.length > 0) {
      setRangestate([
        {
          startDate: new Date(value.options[0].value.split("-").join("/")),
          endDate: new Date(
            value.options[value.options.length - 1].value.split("-").join("/")
          ),
          key: "selection",
        },
      ]);
      // setValue({ ...value, ids: [] })
    }
  }, [value.options]);

  useEffect(() => {
    if (data.wholesalerlist?.length > 0) {
      const arr = [...value.wholesalerIds, ...data.wholesalerlist];
      let pp = arr.filter(
        (ele, ind) =>
          ind === arr.findIndex((elem) => elem.customer_id === ele.customer_id)
      );

      setValue({
        ...value,
        wholesalerIds: pp,
      });
    }
  }, [data.wholesalerlist]);

  const handleDate = (item) => {
    if (
      format(item.selection.endDate, "yyyy-MM-dd") >
      format(item.selection.startDate, "yyyy-MM-dd")
    ) {
      let info = {
        ClientId: 48,
        email_id: data.email,
        registration_type_id: data.selectedType,
        timezone: "Asia/kolkata",
        registration_sub_type_id: data.selectedSubType,
        start_date: format(item.selection.startDate, "yyyy-MM-dd"),
        end_date: format(item.selection.endDate, "yyyy-MM-dd"),
        allow_for_first_time_sponsors: data.allow_for_first_time_sponsors,
      };
      dispatch(getEventIds(appState, dispatch, info));
    }
    // setRangestate([item.selection])
    setRangestate([
      {
        startDate: new Date(item.selection.startDate),
        endDate: new Date(item.selection.endDate),
        key: "selection",
      },
    ]);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubevtClose = () => setSubevtshow(false);
  const handleSubevtShow = () => setSubevtshow(true);

  function findCommonElements2(arr1, arr2) {
    // Create an empty object
    let obj = {};

    // Loop through the first array
    for (let i = 0; i < arr1.length; i++) {
      // Check if element from first array
      // already exist in object or not
      if (!obj[arr1[i]]) {
        // If it doesn't exist assign the
        // properties equals to the
        // elements in the array
        let element = arr1[i];
        obj[element] = true;
      }
    }

    // Loop through the second array
    for (let j = 0; j < arr2.length; j++) {
      // Check elements from second array exist
      // in the created object or not
      if (obj[arr2[j]]) {
        return true;
      }
    }
    return false;
  }

  const handleEvents = (id) => {
    let array = value.ids;
    let selectdguestdatesarr = value.selectedGuestDates;

    const filterWholesalerdata = value.selectedFinalDateList.filter(
      (el) => el.event_id === id
    );
    // console.log(value);
    //unselection event onclick action part
    if (array.includes(id)) {
      const index = array.indexOf(id);
      const indexdate = array.indexOf(filterWholesalerdata[0].event_date);

      array.splice(index, 1);
      selectdguestdatesarr.splice(indexdate, 1);

      setValue({
        ...value,
        ids: array,
        selectedGuestDates: selectdguestdatesarr,
      });
      if (
        data.selectedTypeName === "Sponsor" &&
        ["Gold", "Silver", "Bronze"].includes(data.selectedSubTypeName)
      ) {
        let allSeelectedwholesaler = {
          ...value,
        };
        if (value.wholesalerIds.length > 0) {
          allSeelectedwholesaler = {
            ...allSeelectedwholesaler,
            timeslot: [],
            wholesalerIds: data.wholesalerlist,
            wholesalerevtid: [],
          };
          // setAllValues([]);
        } else {
          setWholesalerevtid([]);
        }
        setValue(allSeelectedwholesaler);
      }
      if (subeventid.length > 0) {
        setSubeventid([]);
      }

      if (value.sub_event_list.length > 0) {
        setValue({
          ...value,
          sub_event_list: [value.sub_event_list, []],
        });
        setAnswers([]);
      }
    }
    //selection event onclick action part
    else {
      const filterWholesalerdata = value.selectedFinalDateList.filter(
        (el) => el.event_id === id
      );

      const allActivityEventIds = value?.eventsList
        ?.filter((each) => each.allow_activity_event === "1")
        ?.map((el) => el.event_id);

      const condition1 = findCommonElements2(value?.ids, allActivityEventIds);
      const condition2 = allActivityEventIds?.includes(id);
      // console.log("conditioncheck", condition1);
      if (condition1 && condition2) {
        toast.error("You can select Only One Activity Event");
        setModal(false);
      } else {
        setModal(true);
        setValue({
          ...value,
          ids: [...value.ids, id],
          selectedGuestDates: [
            ...value.selectedGuestDates,
            filterWholesalerdata[0].event_date,
          ],
        });
      }

      if (
        data.selectedTypeName === "Sponsor" &&
        ["Gold", "Silver", "Bronze"].includes(data.selectedSubTypeName)
      ) {
        if (filterWholesalerdata.length > 0) {
          if (filterWholesalerdata[0].wholesaler_list.length > 0) {
            setWholesalerevtid(id);
            setTimeslots(filterWholesalerdata);
            setOptions(
              handleoptions(filterWholesalerdata[0].multiple_time_slots)
            );
            if (filterWholesalerdata[0].allow_company_list === "1") {
              if (allValues.length === 0) {
                setAllwholesaler(filterWholesalerdata[0].wholesaler_list);
              } else {
                // console.log(filterWholesalerdata[0].wholesaler_list);
                const allwholesalerdataDB =
                  filterWholesalerdata[0].wholesaler_list.filter((eachall) => {
                    return !allValues.find((ele) => {
                      return ele.customer_id === eachall.company_id;
                    });
                  });
                // console.log(allwholesalerdataDB);
                setAllwholesaler(allwholesalerdataDB);
              }
              setShow(true);
            }
          }
        }
      }

      if (checkSubevents(filterWholesalerdata)) {
        setSubevtshow(true);
        setSubeventid(filterWholesalerdata[0].event_id);
      } else {
        setSubevtshow(false);
      }
    }
  };

  //Check Subevents
  const checkSubevents = (subevt) => {
    if (subevt[0].sub_event_list.length > 0) {
      setSubeventlist(subevt[0].sub_event_list);
      return true;
    }
  };

  //Handle selectSubevt
  const selectSubevt = (e) => {
    const selectedsubevt = subeventlist.filter(
      (x) => x.sub_event_id === e.target.value
    );

    if (selectedsubevt[0].question_list.length > 0) {
      setQuestionlist(selectedsubevt[0].question_list);
      setSubevent(selectedsubevt);
    } else {
      // setSubevent(selectedsubevt[0])
      setQuestionlist([]);
      setSubevent(selectedsubevt);

      const event = [];
      selectedsubevt.map((val, idx) => {
        event.push({
          sub_event_id: val.sub_event_id,
          event_id: subeventid,
          title: val.title,
          status: val.status,
          order_by: val.order_by,
          not_allow_event_id: val.not_allow_event_id,
          status_label: val.status_label,
          question_list: [],
        });
      });

      setValue({
        ...value,
        sub_event_list: event,
      });
    }
  };

  const handleAnswers = (e, sub_event_question_id, type) => {
    setRadiovalue({
      ...radiovalue,
      [e.target.name]: e.target.value,
    });

    const question = subevent[0].question_list.filter(
      (el) => el.sub_event_question_id === sub_event_question_id
    );
    const optionvalue = question[0].values.filter(
      (x) => x.sub_event_question_value_id === e.target.value
    );

    const ans = [];

    if (type === "radio") {
      question.map((val, idx) => {
        ans.push({
          sub_event_question_id: val.sub_event_question_id,
          sub_event_id: val.sub_event_id,
          type: val.type,
          title: val.title,
          answer_type: val.answer_type,
          answer: "",
          values: optionvalue,
        });
      });
    } else {
      question.map((val, idx) => {
        const isValidInput = /^\d*$/.test(e.target.value);
        ans.push({
          sub_event_question_id: val.sub_event_question_id,
          sub_event_id: val.sub_event_id,
          type: val.type,
          title: val.title,
          answer_type: val.answer_type,
          answer: isValidInput ? e.target.value : "",
          values: [],
        });
      });
    }

    setAnswers({
      ...answer,
      [e.target.name]: ans,
    });
  };

  const handleoptions = (options) => {
    let arr = options.map((val) => {
      return { value: val };
    });
    return arr;
  };
  // console.log(data);

  //Handle Timeslot
  const handleTimeslot = (e) => {
    if (allValues.length >= timeslots[0].wholesaler_max_select_limit) {
      toast.error("Max Limit Reached");
      setDisable(true);
    } else {
      if (allValues.length > 0) {
        const c = allwholesaler.filter(
          (x) =>
            !allValues.filter((y) => y.customer_id === x.customer_id).length
        );
        setAllwholesaler(c);
      }
      setTimeslot(e.target.value);
    }
  };
  //Handle Wholesaler
  const selectWholesaler = (e) => {
    loadEventsList();
    let limit_arr = eventDates.data.filter(
      (each) =>
        each.allow_company_list === "1" && each.wholesaler_list.length > 0
    );
    let max_limit = limit_arr[0].wholesaler_max_select_limit;
    let min_limit = limit_arr[0].wholesaler_min_select_limit;

    if (allValues.length < parseInt(max_limit)) {
      const object = allwholesaler.filter(
        (obj) => obj.company_name === e.target.value
      );
      setWholesaler(e.target.value);
      let obj = [
        {
          customer_id: object[0].company_id,
          event_id: wholesalerevtid,
          timeslot: timeslot,
          name: object[0].company_name,
        },
      ];

      const isFound = allValues.some((element) => {
        if (element.name === object[0].company_name) {
          return true;
        }
      });

      if (!isFound) {
        setAllValues((allValues) => [...allValues, ...obj]);
        // console.log(options);
        // const optionIndex = options.findIndex((obj) => obj.value === timeslot);
        // options.splice(optionIndex, 1);
        // setOptions(options);

        const wholesalerIndex = allwholesaler.findIndex(
          (obj) => obj.company_name === e.target.value
        );
        allwholesaler.splice(wholesalerIndex, 1);
        setAllwholesaler(allwholesaler);
        setTimeslot([]);
        setWholesaler([]);

        //condition wholesalerId dependent-
        setValue({
          ...value,
          wholesalerIds: [...value.wholesalerIds, ...obj],
        });
      }
    } else {
      toast.error(`You can Select maximum of ${max_limit} wholesalers only `);
    }
  };
  // console.log(value);
  //Handle Delete
  const handleDelete = (e, i) => {
    const new_obj = {
      company_id: allValues[i].customer_id,
      company_name: allValues[i].name,
    };
    allValues.splice(i, 1);
    setAllValues(allValues);

    if (allValues.length >= 0) {
      let condition = allwholesaler.some(
        (each) => each.company_name === new_obj.company_name
      );
      const new_allwholesaler = !condition
        ? [...allwholesaler, new_obj]
        : [...allwholesaler];
      new_allwholesaler.sort((a, b) => a.company_id - b.company_id);
      setAllwholesaler(new_allwholesaler);

      // const d = timeslots[0].multiple_time_slots.filter(
      //   (x) => !allValues.filter((y) => y.timeslot === x).length
      // );
      // let arr = d.map((val) => {
      //   return { value: val };
      // });

      setWholesaler([]);

      setValue({ ...value, wholesalerIds: allValues });
      // setOptions(arr);
    }
    if (allValues.length < timeslots[0].wholesaler_max_select_limit) {
      setDisable(false);
    }

    // if (value.wholesalerIds.length > 0) {

    //   const allvalueIndex = value.wholesalerIds.findIndex(
    //     (obj) => obj.timeslot === e.target.value
    //   );
    //   value.wholesalerIds.splice(allvalueIndex, 1);
    // }
  };

  //Sumbit all the values
  const submitInfo = (action) => {
    let val = { ...value, rangestate: rangestate };
    if (action === "next") {
      if (
        value.selectedFinalDateList &&
        value.selectedFinalDateList.length > 0
      ) {
        if (subeventid.length > 0 && value.sub_event_list.length === 0) {
          toast.error("Please select at least one sub event to proceed.");
        } else {
          if (
            data.selectedTypeName === "Sponsor" &&
            ["Gold", "Silver", "Bronze"].includes(data.selectedSubTypeName)
          ) {
            if (wholesalerevtid.length > 0 && allwholesaler.length > 0) {
              if (value.wholesalerIds.length === 0) {
                if (allValues.length > 0) {
                  let obj = allValues.map((each) => {
                    return {
                      customer_id: each.customer_id,
                      event_id: wholesalerevtid,
                      timeslot: timeslot,
                      name: each.name,
                    };
                  });
                  setValue({
                    ...value,
                    wholesalerIds: [...value.wholesalerIds, ...obj],
                  });
                } else {
                  toast.error(
                    "Please select at least one wholesaler to proceed."
                  );
                }
              } else {
                handleStep(val, action, "dates");
              }
            } else {
              handleStep(val, action, "dates");
            }
          } else {
            handleStep(val, action, "dates");
          }
        }
      } else {
        toast.error("Please select at least one event to proceed.");
      }
    } else handleStep(val, action, "dates");
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-lg-8">
        <ToastContainer />
        {eventDatesLoader ? (
          <div className="home-spinner">
            <ClipLoader color={"#00caeb"} loading={true} size={100} />
          </div>
        ) : (
          <Form>
            <div className="p-17">
              <Row>
                {
                  // console.log(data.rangestate, rangestate)
                }
                {
                  //console.log(value.ids)
                }
                <Col xs={12}>
                  <Form.Group className="mt-16">
                    <Form.Label>Event Dates:</Form.Label>
                    {/* <Select
                                                isMulti
                                                options={value.options}
                                                className={"react-select-container"}
                                                value={value.selectedDateList}
                                                onChange={(e) => handleMutliInput(e, value.eventsList)}
                                            /> */}
                    {value.options.length > 0 ? (
                      <DateRange
                        onChange={(item) => handleDate(item)}
                        editableDateInputs={true}
                        moveRangeOnFirstSelection={false}
                        ranges={rangestate}
                        months={1}
                        direction="horizontal"
                        className="calendarElement"
                        //minDate={new Date(value.options[0].value).toString()}
                        //maxDate={new Date(value.options[0].value).toString()}
                      />
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </div>
            <div className="row g-4 mt-20">
              <div className="col-lg-12">
                <div className="form-inner">
                  <div className="mb-10">
                    <h2>Please Select Events you plan to attend:</h2>
                  </div>
                  <GenericEventDates
                    component={true}
                    selectedEvent={value.selectedFinalDateList}
                    ids={value.ids}
                    default_ids={value.default_ids}
                    handleEvents={handleEvents}
                    selecteIds={value.selecteIds}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
        <div className="arrow-container">
          <div
            className="col-lg-12 text-center mt-20 arrow"
            onClick={() => {
              setAllValues([]);
              setActivityAnswers([]);
              setSelectedQuestionList([]);
              setGuestActivityAnswers([]);
              setSelectedGuestQuestionList([]);
              submitInfo("previous");
            }}
          >
            <BiArrowFromRight size={30} color={"rgba(0, 202, 235, 0.8)"} />
          </div>
          <div
            className="col-lg-12 text-center mt-20 arrow"
            onClick={() => {
              setActivityAnswers(savedAns);
              setSelectedQuestionList(questionlist);
              setSelectedSubEventList(value.sub_event_list);
              submitInfo("next");
            }}
          >
            <BiArrowFromLeft size={30} color={"rgba(0, 202, 235, 0.8)"} />
          </div>
        </div>
        {timeslots.length > 0 && (
          <Modal
            wholesalertimelsot={timeslots}
            handleClose={handleClose}
            show={show}
            timeslot={timeslot}
            wholesaler={wholesaler}
            disable={disable}
            options={options}
            allwholesaler={allwholesaler}
            setAllwholesaler={setAllwholesaler}
            handleTimeslot={handleTimeslot}
            selectWholesaler={selectWholesaler}
            allValues={allValues}
            handleDelete={handleDelete}
            value={value}
            setAllValues={setAllValues}
            rangestate={rangestate}
            wholesaleListSave={wholesaleListSave}
          />
        )}

        {modal && (
          <SubeventPopup
            data={data}
            handleClose={handleSubevtClose}
            show={subevtshow}
            subeventlist={subeventlist}
            selectSubevt={selectSubevt}
            questionlist={questionlist}
            handleAnswers={handleAnswers}
            radiovalue={radiovalue}
            answer={answer}
            savedAns={savedAns}
            setSavedAns={setSavedAns}
            subevent={subevent}
            subeventid={subeventid}
            value={value}
            setValue={setValue}
          />
        )}
      </div>
    </div>
  );
}

export default EventDates;
