import React from "react";
import { Link } from "react-router-dom";

function Blog({ blog }) {
  return (
    <>
      <div className="blog-grid-section pt-120 pb-120" id="blog-grid">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-title2 primary6">
                <span>-{blog.main_title}-</span>
                <div>
                  <h3>{blog.highlist_title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row position-relative justify-content-center g-4">
            {
              blog.blogs_list.map(info => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-10" key={info.id}>
                    <div
                      className="single-blog-1 style-2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.6s"
                    >
                      <div className="blog-image">
                        <img
                          src={info.image}
                          style = {{width : "416px", height:"297px"}}
                          className="img-fluid"
                          alt="blog"
                        />
                      </div>
                      <div className="blog-content hover-border2">
                        <span>{info.category_title}</span>
                        <h4>
                          <a target={"_blank"} rel={"noreferer noopener"} href={info.target_url}>
                           {info.title}
                          </a>
                        </h4>
                        <div className="blog-meta">
                          <div className="designation">
                            <h5>{info.author_name}</h5>
                            <div className="date">
                              {info.posted_date}<span>{info.read_time}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
