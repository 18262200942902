import React from 'react';
import ReactPlayer from 'react-player'

const Video = ({ handleVideoModal, url }) => {
    return (
        <div className='modal-video'>
            <div className='modal-video-body'>
                <div className='modal-video-inner' style={{ width: "1326px" }}>
                    <div className='modal-video-movie-wrap' style={{ paddingBottom: "56.25%" }}>
                        <button className='modal-video-close-btn' onClick={handleVideoModal}></button>
                        <ReactPlayer
                            playing={true}
                            url={url}
                            height={"100%"}
                            width={"100%"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Video