import React, { useState, useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "./table.css";
import "./checkbox.css";
import { ConvertArray } from "../components/Generic/Helper";

const Subevent = ({
  data,
  subeventlist,
  handleClose,
  show,
  selectSubevt,
  handleAnswers,
  questionlist,
  answer,
  savedAns,
  setSavedAns,
  subevent,
  guest,
  guestValue,
  subeventid,
  setValue,
  value,
}) => {
  const arrayres = Object.values(answer);
  const convertArr = ConvertArray(arrayres);

  // console.log("Questions", questionlist);
  // console.log("answers", mappQuestionList);
  // console.log("answers onchange", convertArr);
  // console.log("savedAns", savedAns);
  // console.log("selected subevent", subevent);
  // console.log("guest", guest);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Subevents</Modal.Title>
      </Modal.Header>

      <ToastContainer />

      <Modal.Body>
        <div
          className="row"
          style={{ columns: "auto 2", margin: "4px", padding: "3px" }}
        >
          <Form.Label>Sub Events:</Form.Label>

          {subeventlist?.length > 0 ? (
            <select
              name="contact_question_option"
              className="select_questions"
              style={{
                border: "1px solid #dddddd",
                borderRadius: "5px",
                padding: "15px 15px",
                width: "100%",
                transition: "all 0.5s ease",
              }}
              onChange={(e) => {
                selectSubevt(e);
                setSavedAns([]);
              }}
              value={savedAns && savedAns[0]?.sub_event_id}
            >
              <option
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  fontFamily: "Poppins",
                }}
                value=""
              >
                Select Subevent
              </option>
              {subeventlist.map((val, idx) => {
                return (
                  <option
                    value={val.sub_event_id}
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    }}
                  >
                    {val.title}{" "}
                  </option>
                );
              })}
            </select>
          ) : (
            ""
          )}
        </div>

        {questionlist.length > 0 ? (
          <div
            className="row"
            style={{ columns: "auto 2", margin: "10px", padding: "2px" }}
          >
            <Form.Label>
              Answer Questions:{" "}
              <span>
                {savedAns?.length > 0 && savedAns[0]?.values?.length > 0 && (
                  <Button variant="warning" onClick={() => setSavedAns([])}>
                    Change Answers
                  </Button>
                )}
              </span>
            </Form.Label>
            <Col xs="12" sm="12" style={{ textAlign: "left" }}>
              <div class="inbox">
                <div class="quiz-container" id="quiz">
                  {questionlist.map((val, idx) => {
                    return (
                      <div class="quiz-header">
                        <h2 id="question"> {val.title}</h2>

                        <ul>
                          {val.type === "radio" ? (
                            <>
                              {val.values.map((val, idx) => {
                                return (
                                  <li>
                                    <input
                                      type="radio"
                                      id={`radio-${val.sub_event_question_id}-${idx}`}
                                      name={`question_${val.sub_event_question_id}`}
                                      //checked={radiovalue.group-`${val.sub_event_question_id} === ${val.sub_event_question_value_id}`}

                                      disabled={
                                        savedAns?.length > 0 &&
                                        savedAns[0]?.values?.length > 0
                                      }
                                      value={val.sub_event_question_value_id}
                                      onChange={(e) => {
                                        handleAnswers(
                                          e,
                                          val.sub_event_question_id,
                                          "radio"
                                        );
                                      }}
                                    />

                                    <label
                                      for="a"
                                      id="a_text"
                                      htmlFor={`radio-${val.sub_event_question_id}-${idx}`}
                                    >
                                      {" "}
                                      {val.title}
                                      {savedAns &&
                                        savedAns[0]?.values?.length > 0 &&
                                        val.sub_event_question_value_id ===
                                          savedAns.filter(
                                            (each) =>
                                              each.sub_event_question_id ===
                                              val.sub_event_question_id
                                          )[0]?.values[0]
                                            ?.sub_event_question_value_id && (
                                          <span>
                                            {"  "}
                                            <i
                                              class="fa fa-check-square"
                                              aria-hidden="true"
                                              style={{ color: "blue" }}
                                            ></i>
                                            <i class="fa-sharp fa-solid fa-circle-check"></i>
                                          </span>
                                        )}
                                    </label>
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                          {val.type === "textbox" ? (
                            <>
                              <li>
                                <input
                                  type="text"
                                  name={`question_${val.sub_event_question_id}`}
                                  onChange={(e) => {
                                    handleAnswers(
                                      e,
                                      val.sub_event_question_id,
                                      "text"
                                    );
                                  }}
                                  disabled={
                                    savedAns?.length > 0 &&
                                    savedAns[0]?.values?.length > 0
                                  }
                                  value={
                                    savedAns?.length > 0
                                      ? savedAns.filter(
                                          (each) =>
                                            each.sub_event_question_id ===
                                            val.sub_event_question_id
                                        )[0]?.answer
                                      : convertArr?.length > 0
                                      ? val.sub_event_question_value_id
                                      : ""
                                  }
                                  class="answer"
                                />
                                <span
                                  style={{ color: "red", fontSize: "1rem" }}
                                >
                                  {" "}
                                  * Number only
                                </span>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
          </div>
        ) : (
          ""
        )}

        {convertArr.length > 0 && questionlist.length > 0 ? (
          <div
            className="row"
            style={{ columns: "auto 2", marginTop: "-50px", padding: "3px" }}
          >
            <Col xs="12" sm="12" style={{ textAlign: "left", margin: "12px" }}>
              <table>
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {convertArr.map((val, idx) => {
                    return (
                      <tr>
                        <td data-column="First Name">{val?.title} </td>
                        <td data-column="Last Name">
                          {" "}
                          {val.type === "radio"
                            ? val.values[0].title
                            : val.answer}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>

      <Modal.Footer>
        {savedAns?.length > 0 && savedAns[0]?.values?.length > 0 ? (
          <Button variant="secondary" onClick={handleClose}>
            Continue
          </Button>
        ) : (
          <Button
            variant="secondary"
            onClick={() => {
              if (questionlist?.length === 0) {
                setSavedAns([
                  {
                    sub_event_id: guest
                      ? guestValue?.guest_sub_event_list[0]?.sub_event_id
                      : subevent && subevent[0]?.sub_event_id,
                  },
                ]);
                handleClose();
              } else {
                let condition =
                  convertArr?.length === questionlist?.length &&
                  convertArr?.filter(
                    (each) => each.answer_type === "numeric"
                  )[0].answer !== "";
                if (condition) {
                  setSavedAns(convertArr);
                  handleClose();

                  var object = [];
                  arrayres.map((issue, idx) => {
                    object[idx] = issue[0];
                    return object;
                  });

                  const event = [];

                  subevent.map((val, idx) => {
                    event.push({
                      sub_event_id: val.sub_event_id,
                      event_id: subeventid,
                      title: val.title,
                      status: val.status,
                      order_by: val.order_by,
                      not_allow_event_id: val.not_allow_event_id,
                      status_label: val.status_label,
                      question_list: object,
                    });
                  });
                  if (guest)
                    setValue({
                      ...value,
                      guest_sub_event_list: event,
                    });
                  else
                    setValue({
                      ...value,
                      sub_event_list: event,
                    });
                } else {
                  toast.error("Please answer all the questions");
                }
              }
            }}
          >
            Save
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Subevent;
