import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import { getConferenceDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import ConferenceDetails from "./ConferenceDetails";
import Sponsors from "./Sponsors";

const Conference = () => {
  const { appState, dispatch } = useContext(AppContext);
  const { conference, conferenceLoader } = appState;
  const myRef = useRef();
  const golf_ref = useRef();

  useEffect(() => {
    getConferenceDetails(appState, dispatch);
  }, []);

  console.log("conference", conference);
  return (
    <React.Fragment>
      {conferenceLoader ? (
        <div className="home-spinner">
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        Object.keys(conference).length && (
          <React.Fragment>
            <Breadcrumb
              pageName={conference.header_services_section.title}
              pageTitle={conference.header_services_section.title}
              id={`#${conference.header_services_section.title}`}
              image={conference.header_services_section.banner_image}
              myRef={myRef}
            />
            <div ref={myRef}>
              <ConferenceDetails conference={conference} />
            </div>
            {conference.golf_sponsor_section &&
              conference.golf_sponsor_section?.partner_list?.length && (
                <div ref={golf_ref}>
                  <Sponsors
                    sponsor={conference.golf_sponsor_section}
                    index="1"
                  />
                </div>
              )}
            {conference.sponsor_section &&
              conference.sponsor_section?.partner_list?.length && (
                <div ref={myRef}>
                  <Sponsors sponsor={conference.sponsor_section} index="2" />
                </div>
              )}
          </React.Fragment>
        )
      )}
    </React.Fragment>
  );
};

export default Conference;
