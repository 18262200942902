import React from "react";

function HistoryDetails({history}) {
  return (
    <>
      <div className="service2-section pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-title2 primary4">
                <span>-{history.highlist_title}-</span>
                <div>
                  <h3>{history.sub_title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4">
          {
                history.services_list && history.services_list.length &&
                history.services_list.map((history,index) => {
                    return (
                    <div className="col-lg-4 col-md-6 col-sm-10">
                    <div
                        className="service-item2 wow fadeInDown"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.2s"
                    >
                        <div className="service-content">
                        {/* <span>{index + 1}</span> */}
                        <span><h4>
                        
                             <a href={`/${history.target_url}`}>{history.title}</a>
                     
                        </h4></span>

                        <p className="para">
                            {history.short_description}
                        </p>
                        </div>
                    </div>
                    </div>
                    )})
                    }
          </div>
        </div>
      </div>
    </>
  );
}

export default HistoryDetails;
