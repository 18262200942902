import React, { useContext, useState, useEffect, useRef } from "react";
import { submitInvitationReq } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import { toast, ToastContainer } from "react-toastify";
import Breadcrumb from "../Generic/Breadcrumb";
import { ClipLoader } from "react-spinners";

function ReqInvitation() {
  const { appState, dispatch } = useContext(AppContext);
  const { submitInvitationReqEventMessage, submitInvitationReqEventLoader } =
    appState;
  const [sucess, setSuccess] = useState(false);
  const myRef = useRef(null);

  const [value, setValue] = useState({
    ClientId: 48,
    email_id: "",
    first_name: "",
    last_name: "",
    phone_number: "",
  });
  const [error, setError] = useState({
    email_id: "",
    first_name: "",
    last_name: "",
    phone_number: "",
  });
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (submitInvitationReqEventMessage !== "") {
      if (
        submitInvitationReqEventMessage ===
        "Thank you for your request. Our team will reach out to you soon."
      ) {
        setSuccess(true);
        toast.success(submitInvitationReqEventMessage);
      } else {
        toast.error(submitInvitationReqEventMessage);
      }
    }
  }, [submitInvitationReqEventMessage]);

  const handleSubmit = () => {
    const { first_name, last_name, email_id, phone_number } = value;
    let flag = true;
    var pattern = new RegExp(/^[0-9\b]+$/);
    var emailPattern = new RegExp(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/);
    let err = { ...error };
    if (first_name === "") {
      err = {
        ...err,
        first_name: "Please enter first name.",
      };
      flag = false;
    }
    if (last_name === "") {
      err = {
        ...err,
        last_name: "Please enter last name.",
      };
      flag = false;
    }
    if (phone_number === "") {
      err = {
        ...err,
        phone_number: "Please enter Phone number.",
      };
      flag = false;
    }

    if (!pattern.test(document.querySelector("#phone_number").value)) {
      err = {
        ...err,
        phone_number: "Please enter valid Phone number.",
      };
      flag = false;
    }
    if (email_id === "") {
      err = {
        ...err,
        email_id: "Please enter Email Id.",
      };
      flag = false;
    }

    if (!emailPattern.test(document.querySelector("#email_id").value)) {
      err = {
        ...err,
        email_id: "Please enter valid Email Id.",
      };
      flag = false;
    }
    if (flag) submitInvitationReq(appState, dispatch, value);
    else setError(err);
  };
  return (
    <React.Fragment>
      <Breadcrumb
        pageName={"Invitation Request"}
        pageTitle={"Invitation Request"}
        id={`#$InvitationRequest`}
        myRef={myRef}
      />
      <ToastContainer />
      <div className="form-section pt-120 pb-120" id="contact" ref={myRef}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6">
              <div className="section-title primary4">
                <span>-Invitation Request-</span>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      onChange={(e) => {
                        setError({
                          ...error,
                          first_name: "",
                        });
                        handleChange(e);
                      }}
                      value={value.first_name || ""}
                      className={`input ${error.first_name && "is-danger"}`}
                    />
                    {error.first_name && (
                      <p
                        className="help is-danger"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          padding: "2px",
                        }}
                      >
                        {error.first_name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      onChange={(e) => {
                        setError({
                          ...error,
                          last_name: "",
                        });
                        handleChange(e);
                      }}
                      value={value.last_name || ""}
                      className={`input ${error.last_name && "is-danger"}`}
                    />
                    {error.last_name && (
                      <p
                        className="help is-danger"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          padding: "2px",
                        }}
                      >
                        {error.last_name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="email_id"
                      id="email_id"
                      placeholder="Email Id"
                      onChange={(e) => {
                        setError({
                          ...error,
                          email_id: "",
                        });
                        handleChange(e);
                      }}
                      value={value.email_id || ""}
                      className={`input ${error.email_id && "is-danger"}`}
                    />
                    {error.email_id && (
                      <p
                        className="help is-danger"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          padding: "2px",
                        }}
                      >
                        {error.email_id}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="phone_number"
                      id="phone_number"
                      placeholder="Phone Number"
                      onChange={(e) => {
                        setError({
                          ...error,
                          phone_number: "",
                        });
                        handleChange(e);
                      }}
                      value={value.phone_number || ""}
                      className={`input ${error.phone_number && "is-danger"}`}
                    />
                    {error.phone_number && (
                      <p
                        className="help is-danger"
                        style={{
                          color: "red",
                          fontSize: "12px",
                          padding: "2px",
                        }}
                      >
                        {error.phone_number}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 text-center">
                  {submitInvitationReqEventLoader ? (
                    <div className="mt-20">
                      <ClipLoader color={"#00caeb"} loading={true} size={50} />
                    </div>
                  ) : (
                    <div className="eg-btn btn--submit" onClick={handleSubmit}>
                      Submit
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ReqInvitation;
