import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";

SwiperCore.use([Navigation, Pagination, Autoplay]);

function Portfolio({ portfolio }) {
  const protfolio2slider = {
    slidesPerView: "auto",
    speed: 1200,
    spaceBetween: 20,
    autoplay: true,
    loop: true,
    roundLengths: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: "true",
    },
    navigation: {
      nextEl: ".portfolio-prev2",
      prevEl: ".portfolio-next2",
    },

    breakpoints: {
      280: {
        slidesPerView: 1,
        navigation: false,
      },
      480: {
        slidesPerView: 1,
        navigation: false,
      },
      768: {
        slidesPerView: 2,
        navigation: false,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };
  return (
    <>
      <div className="portfolio-section pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-title2 primary1">
                <span>-{portfolio.main_title}-</span>
                <div>
                  <h3>{portfolio.highlist_title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row position-relative justify-content-center">
            <Swiper
              {...protfolio2slider}
              className="swiper portfolio-slider2 swiper-fix"
            >
              <div className="swiper-wrapper">
                {portfolio.portfolio_list.map((info, index) => {
                  return (
                    <SwiperSlide className="swiper-slide" key={index}>
                      <div
                        className="portfolio-item1 style-2 wow fadeInDown"
                        data-wow-duration="1.5s"
                        data-wow-delay="0.2s"
                      >
                        {info.target_url === "" ? (
                          <div className="portfolio-img">
                            <img alt="info" src={info.image} />
                          </div>
                        ) : (
                          <div className="portfolio-img">
                            <Link to="/past-events">
                              <img alt="info" src={info.image} />
                            </Link>
                          </div>
                        )}

                        <div className="portfolio-content">
                          {/* <span>App Design</span> */}
                          <h4>
                            <Link to="/project-details">
                              {info.services_title}
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </div>
            </Swiper>
            <div className="slider-bottom d-flex justify-content-between align-items-center">
              <div className="swiper-pagination style-2 d-lg-block d-none" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Portfolio;
