import React, { useContext, useEffect, useRef, useState } from "react";
import Breadcrumb from "../Generic/Breadcrumb";
import { AppContext } from "../Redux/ContextProvider";
import EmailVerification from "./EmailVerification";
import EventDates from "./EventDates";
import Options from "./Options";
import BasicInfo from "./BasicInfo";
import Type from "./Type";
import Guest from "./Guest";
import { registerData } from "../Redux/API";
import { toast, ToastContainer } from "react-toastify";
import Preview from "./Preview";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

const Registration = () => {
  const { appState, dispatch } = useContext(AppContext);
  const {
    registerEvent,
    registerEventLoader,
    registerEventMessage,
    eventDates,
  } = appState;
  const [allValues, setAllValues] = useState([]);
  const [activityAnswers, setActivityAnswers] = useState();
  const [guestActivityAnswers, setGuestActivityAnswers] = useState();
  const [selectedQuestionList, setSelectedQuestionList] = useState();
  const [selectedSubEventList, setSelectedSubEventList] = useState();

  const [selectedGuestQuestionList, setSelectedGuestQuestionList] = useState();
  const [selectedGuestSubEventList, setSelectedGuestSubEventList] = useState();

  const [step, setStep] = useState(0);
  const myRef = useRef(null);

  const [data, setData] = useState({
    email: "",
    fname: "",
    lname: "",
    company: "",
    address: "",
    mobile: "",
    logo: "",
    city: "",
    zip_code: "",
    state: "",
    selectedType: "",
    selectedSubType: "",
    selectedTypeName: "",
    selectedSubTypeName: "",
    guest: false,
    guestFname: "",
    guestLname: "",
    guestMobile: "",
    amount: 0,
    type: [],
    sub_event_list: [],
    subType: [],
    option: [],
    options: [],
    selectedList: [],
    optionsList: [],
    eventIds: [],
    eventsList: [],
    guestEventIds: [],
    selectedDateList: [],
    selectedFinalDateList: [],
    guestSelectedFinalDateList: [],
    selectedGuestDates: [],
    guestSelectedDateList: [],
    guestSelectedList: [],
    tempIds: [],
    rangestate: [
      {
        startDate: new Date("2022/11/10"),
        endDate: new Date("2022/11/15"),
        key: "selection",
      },
    ],
    bonusFee: 0,
  });
  // console.log(data);
  const [success, setSuccess] = useState(false);
  const [register, setRegister] = useState(false);

  const handleStepCount = (action) => {
    if (action === "next") setStep(step + 1);
    else if (action === "previous") setStep(step - 1);
  };
  const handleStep = (info, action, category) => {
    if (category === "email") {
      setData({
        ...data,
        email: info.email,
        type: info.registration_type_list,
        subType: info.registration_sub_type_list,
        option: info.sponsors_bonus_option_list,
      });
      handleStepCount(action);
    } else if (category === "basic") {
      setData({
        ...data,
        fname: info.fname,
        lname: info.lname,
        company: info.company,
        address: info.address,
        mobile: info.mobile,
        logo: info.logo,
        city: info.city,
        zip_code: info.zip_code,
        state: info.state,
      });
      handleStepCount(action);
    } else if (category === "type") {
      setData({
        ...data,
        selectedType: info.selectedType,
        selectedSubType: info.selectedSubType,
        selectedTypeName: info.selectedTypeName,
        selectedSubTypeName: info.selectedSubTypeName,
        amount: info.typeFee,
        allow_for_first_time_sponsors: info.first_time_sponosr,
      });
      handleStepCount(action);
    } else if (category === "list") {
      setData({
        ...data,
        selectedList: info.selectedList,
        optionsList: info.optionsList,
        amount: info.bonusFee + data.amount,
        wholesalerlist: [],
        time_slot: info.timeslot,
        bonusFee: info.bonusFee,
      });
      handleStepCount(action);
    } else if (category === "dates") {
      setData({
        ...data,
        tempIds: info.ids,
        eventIds: [...info.ids, ...info.default_ids],
        eventsList: info.eventsList,
        options: info.options,
        wholesalerlist: info.wholesalerIds,
        time_slot: info.timeslot,
        selectwholesalerlist: info.selectwholesalerlist,
        selectedGuestDates: info.selectedGuestDates,
        selectedDateList: info.selectedDateList,
        selectedFinalDateList: info.selectedFinalDateList,
        rangestate: info.rangestate,
        sub_event_list: info.sub_event_list,
      });
      handleStepCount(action);
    } else if (category === "guest") {
      setData({
        ...data,
        guestFname: info.guestFname,
        guest: info.guest,
        guestLname: info.guestLname,
        guestMobile: info.guestMobile,
        guestEventIds: info.guestEventIds,
        guestSelectedFinalDateList: info.guestSelectedFinalDateList,
        guestSelectedDateList: info.guestSelectedDateList,
        guestSelectedList: info.guestSelectedList,
        guest_sub_event_list: info.guest_sub_event_list,
      });
      handleStepCount(action);
    } else if (category === "preview") {
      if (action === "next") setRegister(true);
      else handleStepCount(action);
    }
  };

  const wholesaleListSave = (info) => {
    setData({
      ...data,
      tempIds: info.ids,
      eventIds: [...info.ids, ...info.default_ids],
      eventsList: info.eventsList,
      options: info.options,
      wholesalerlist: info.wholesalerIds,
      time_slot: info.timeslot,
      selectwholesalerlist: info.selectwholesalerlist,
      selectedGuestDates: info.selectedGuestDates,
      selectedDateList: info.selectedDateList,
      selectedFinalDateList: info.selectedFinalDateList,
      rangestate: info.rangestate,
      sub_event_list: info.sub_event_list,
    });
  };
  useEffect(() => {
    if (register) {
      registerData(appState, dispatch, data);
    }
  }, [register]);

  useEffect(() => {
    scrollTo();
  });

  const scrollTo = () => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (registerEventMessage !== "") {
      if (registerEventMessage === "Event Registration Successfully.") {
        setSuccess(true);
      } else toast.error(registerEventMessage);
    }
  }, [registerEventMessage]);

  return (
    <React.Fragment>
      <Breadcrumb
        pageName={"Registration"}
        pageTitle={"Registration"}
        id={`#$Registration`}
        myRef={myRef}
      />
      <ToastContainer />
      <div className="registration" ref={scrollTo}>
        {success ? (
          <div className="form-section pt-120 pb-120" id="contact">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div className="section-title primary4">
                    <span>-Confirmation!-</span>
                    <h3>Your registration was successful. </h3>
                    <p className="para">
                      You will receive an invoice from ROFDA for your
                      registration and/or sponsorship fees. If you have any
                      questions, please contact Teresa @ <b>205.683.3400. </b>
                      Looking forward to see you at conference.
                      <span style={{ color: "#3498db" }}>
                        {" "}
                        <a
                          href="https://bookings.travelclick.com/110245?groupID=3708826&hotelID=110245#/guestsandrooms"
                          target="_blank"
                          rel="noreferrer"
                        >
                          You can now book your accomodation with us
                        </a>
                      </span>
                    </p>

                    {/* You can now book your accomodation with us at https://www.omnihotels.com/hotels/orlando-championsgate/meetings/rofda-fall-2022-conference-11062022 */}
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Button className="btn--primary4" variant="success">
                      <a
                        href={eventDates?.conference_room_booking_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        I want a Room
                      </a>
                    </Button>
                    <Button variant="danger">
                      <Link to="/login">I don't want a Room</Link>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="form-section pt-120 pb-120" id="contact">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div className="section-title primary4">
                    <span>-Registration-</span>
                    <br />
                    <h4>
                      {Object.keys(data).length > 0
                        ? !!data.email
                          ? `${data.email}`
                          : ""
                        : ""}
                      {Object.keys(data).length > 0
                        ? data.selectedTypeName
                          ? ` | ${data.selectedTypeName} | `
                          : ""
                        : ""}
                      {Object.keys(data).length > 0
                        ? data.selectedSubTypeName
                          ? `${data.selectedSubTypeName} | `
                          : ""
                        : ""}
                      {Object.keys(data).length > 0
                        ? data.amount !== 0
                          ? `Fee: $${data.amount}`
                          : ""
                        : ""}
                    </h4>
                  </div>
                </div>
              </div>
              {step === 0 && <EmailVerification handleStep={handleStep} />}
              {step === 1 && <BasicInfo data={data} handleStep={handleStep} />}
              {step === 2 && (
                <Type data={data} handleStep={handleStep} setData={setData} />
              )}
              {data.selectedTypeName === "Sponsor" ? (
                step === 3 ? (
                  <Options
                    data={data}
                    handleStep={handleStep}
                    setData={setData}
                  />
                ) : data.selectedSubTypeName !== "Non-attending" ? (
                  step === 4 ? (
                    <EventDates
                      data={data}
                      handleStep={handleStep}
                      allValues={allValues}
                      setAllValues={setAllValues}
                      wholesaleListSave={wholesaleListSave}
                      activityAnswers={activityAnswers}
                      setActivityAnswers={setActivityAnswers}
                      setSelectedQuestionList={setSelectedQuestionList}
                      selectedQuestionList={selectedQuestionList}
                      setSelectedSubEventList={setSelectedSubEventList}
                      selectedSubEventList={selectedSubEventList}
                      setGuestActivityAnswers={setGuestActivityAnswers}
                      setSelectedGuestQuestionList={
                        setSelectedGuestQuestionList
                      }
                    />
                  ) : step === 5 ? (
                    <Guest
                      data={data}
                      handleStep={handleStep}
                      guestActivityAnswers={guestActivityAnswers}
                      setGuestActivityAnswers={setGuestActivityAnswers}
                      selectedGuestQuestionList={selectedGuestQuestionList}
                      setSelectedGuestQuestionList={
                        setSelectedGuestQuestionList
                      }
                      selectedGuestSubEventList={selectedGuestSubEventList}
                      setSelectedGuestSubEventList={
                        setSelectedGuestSubEventList
                      }
                    />
                  ) : (
                    step === 6 && (
                      <Preview
                        data={data}
                        setData={setData}
                        handleStep={handleStep}
                      />
                    )
                  )
                ) : (
                  step === 4 && <Preview data={data} handleStep={handleStep} />
                )
              ) : step === 3 ? (
                <EventDates
                  data={data}
                  handleStep={handleStep}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  wholesaleListSave={wholesaleListSave}
                  activityAnswers={activityAnswers}
                  setActivityAnswers={setActivityAnswers}
                  setSelectedQuestionList={setSelectedQuestionList}
                  selectedQuestionList={selectedQuestionList}
                  setSelectedSubEventList={setSelectedSubEventList}
                  selectedSubEventList={selectedSubEventList}
                  setGuestActivityAnswers={setGuestActivityAnswers}
                  setSelectedGuestQuestionList={setSelectedGuestQuestionList}
                />
              ) : step === 4 ? (
                <Guest
                  data={data}
                  handleStep={handleStep}
                  guestActivityAnswers={guestActivityAnswers}
                  setGuestActivityAnswers={setGuestActivityAnswers}
                  selectedGuestQuestionList={selectedGuestQuestionList}
                  setSelectedGuestQuestionList={setSelectedGuestQuestionList}
                  selectedGuestSubEventList={selectedGuestSubEventList}
                  setSelectedGuestSubEventList={setSelectedGuestSubEventList}
                />
              ) : (
                step === 5 && <Preview data={data} handleStep={handleStep} />
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default Registration;
