import React from "react";
import ClientIconSvg from "../../public/assets/images/icons/client-icon.svg";
import CategoryIconSvg from "../../public/assets/images/icons/category-icon.svg";
import Calendar2IconSvg from "../../public/assets/images/icons/calendar2.svg";
import QuoteWhite from "../../public/assets/images/icons/quote-white.svg";
import TestBg from "../../public/assets/images/bg/testi2-bg.svg";
const ConferenceDetails = ({ conference }) => {
  return (
    <>
      <div className="project-details-section pt-120" id="project-details">
        <div className="container">
          <div className="row justify-contenet-center gy-5">
            <div className="col-lg-7">
              <div className="proj-details-single">
                <img
                  src={conference.image}
                  alt="conference"
                  className="img-fluid"
                />
                <ul className="project-meta-list gap-3">
                  <li>
                    <div className="icon">
                      <img src={ClientIconSvg} alt="client" />
                    </div>
                    <div className="text">
                      <h5>Venue</h5>
                      <p>{conference.venue_name}</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={CategoryIconSvg} alt="category" />
                    </div>
                    <div className="text">
                      <h5>Address</h5>
                      <p>{conference.address}</p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src={Calendar2IconSvg} alt="Calendar2" />
                    </div>
                    <div className="text">
                      <h5>Start Date</h5>
                      <p>{conference.start_date}</p>
                    </div>
                  </li>
                  <li style={{ marginRight: "245px" }}>
                    <div className="icon">
                      <img src={Calendar2IconSvg} alt="Calendar2" />
                    </div>
                    <div className="text">
                      <h5>End Date</h5>
                      <p>{conference.end_date}</p>
                    </div>
                  </li>
                </ul>
                {conference.event_section &&
                  conference.event_section &&
                  conference.event_section.map((event) => {
                    return (
                      <div className="challenge-box">
                        <h4>{event.title}</h4>
                        <p className="para">{event.short_description}</p>
                        <ul className="challenge-list">
                          {event.list &&
                            event.list &&
                            event.list.map((record) => {
                              return <li key={record.title}>{record.title}</li>;
                            })}
                        </ul>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-5">
              <div className="proj-details-right">
                <div className="title-area">
                  <span>{conference.event_category_name}</span>
                  <h2>{conference.event_name}</h2>
                </div>
                <p className="para">{conference.short_description}</p>
                <div
                  className="proj-details-imgs"
                  style={{ marinTop: "0px", marginBottom: "-50px" }}
                >
                  <div className="row">
                    {conference.gallery &&
                      conference.gallery.length &&
                      conference.gallery.map((image) => {
                        return (
                          <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                            <img
                              src={image.image}
                              className="img-fluid"
                              alt="Projectd1Image"
                            />
                          </div>
                        );
                      })}
                    <div className="col-lg-12 col-md-12 col-sm-12 text-left">
                      {/* <a
                        href="https://www.omnihotels.com/hotels/orlando-championsgate/meetings/rofda-fall-2022-conference-11062022"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p
                          style={{
                            margin: "10px",
                            fontWeight: "600",
                            fontSize: "15px",
                            color: "#00caeb",
                          }}
                          className="para"
                        >
                          Reserve Your Hotel Room @ the Omni Orlando
                          ChampionsGate{" "}
                        </p>
                      </a>

                      
                      <a
                        href="https://docs.google.com/spreadsheets/d/1IFe8co43Q4arKzP6VUN7aTS79vuUYQWLBHV-VJUJ-_4/edit?usp=sharing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <p
                          style={{
                            margin: "10px",
                            fontWeight: "600",
                            fontSize: "15px",
                            color: "#00caeb",
                          }}
                          className="para"
                        >
                          Conference Agenda{" "}
                        </p>
                      </a>

                      <a
                        class="eg-btn btn--primary2 btn--lg"
                        rel=""
                        href="/req-invitation"
                      >
                        Request Invitation
                      </a> */}
                    </div>
                  </div>
                </div>
                {Object.keys(conference.feedback_section) && (
                  <React.Fragment>
                    {conference.feedback_section.title !== "" && (
                      <h3>{conference.feedback_section.title}</h3>
                    )}
                    {conference.feedback_section.list &&
                      conference.feedback_section.list.length &&
                      conference.feedback_section.list.map((feedback) => {
                        return (
                          <div
                            className="testimonial-single2 hover-border2 wow fadeInDown"
                            data-wow-duration="1.5s"
                            data-wow-delay=".4s"
                          >
                            <div className="quote">
                              <img
                                alt="QuoteWhite"
                                src={QuoteWhite}
                                className="quote-icon"
                              />
                            </div>
                            <img
                              src={TestBg}
                              alt="TestBg"
                              className="testi2-bg"
                            />
                            <div className="author">
                              <div className="author-img">
                                <img
                                  src={feedback.image}
                                  alt={feedback.image}
                                />
                              </div>
                              <div className="author-desig">
                                <h5>{feedback.customer_name}</h5>
                                <p>{feedback.designation}</p>
                              </div>
                            </div>
                            <p className="para">{feedback.review}</p>
                          </div>
                        );
                      })}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ConferenceDetails;
