import React, { useContext, useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import { getTeamDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import Team from "./Team";

function Teams() {
  const { appState, dispatch } = useContext(AppContext);
  const { ourTeam, ourTeamLoader } = appState;
  const myRef = useRef(null);

  useEffect(() => {
    getTeamDetails(appState, dispatch)
  }, [])

  return (
    <React.Fragment>
      {
        ourTeamLoader ?
          <div className='home-spinner'>
            <ClipLoader color={"#00caeb"} loading={true} size={100} />
          </div>
          :
          Object.keys(ourTeam).length &&
          <React.Fragment>
            <Breadcrumb
              pageName={ourTeam.header_team_section.title}
              pageTitle={ourTeam.header_team_section.title}
              id={`#${ourTeam.header_team_section.title}`}
              image={ourTeam.header_team_section.banner_image}
              myRef={myRef}
            />
            <div className="team-section2 pt-120 pb-120" ref={myRef}>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <div className="section-title2 primary4">
                      <span>-{ourTeam.header_team_section.title}-</span>
                    </div>
                  </div>
                </div>
                {
                  ourTeam.team_section.map(section => {
                    return <Team team={section} />
                  })
                }
              </div>
            </div>
          </React.Fragment>
      }
    </React.Fragment>
  );
}

export default Teams;
