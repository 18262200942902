import React, { useContext, useEffect, useRef } from "react";
import { ClipLoader } from "react-spinners";
import Breadcrumb from "../Generic/Breadcrumb";
import { getPartnerDeatils } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import Partner from "./Partner";

const OurPartners = () => {
  const { appState, dispatch } = useContext(AppContext);
  const { partner, partnerLoader } = appState;
  const myRef = useRef(null);

  useEffect(() => {
    getPartnerDeatils(appState, dispatch)
  }, [])

  return (
    <React.Fragment>
      {
        partnerLoader ?
          <div className='home-spinner'>
            <ClipLoader color={"#00caeb"} loading={true} size={100} />
          </div>
          :
          Object.keys(partner).length &&
          <React.Fragment>
            <Breadcrumb
              pageName={partner.header_services_section.title}
              pageTitle={partner.header_services_section.title}
              id={`#${partner.header_services_section.title}`}
              image={partner.header_services_section.banner_image}
              myRef={myRef}
            />
            {
              partner.section_list && partner.section_list.length &&
              partner.section_list.map((section, index) => {
                return (
                  <div ref={myRef}>
                    <Partner partner={section} index={index} />
                  </div>
                )
              })
            }

          </React.Fragment>
      }
    </React.Fragment>
  );
}

export default OurPartners;