import React, { useContext, useEffect } from "react";
import AboutUs from "../About";
import { Route, Switch } from "react-router-dom";
import Logo from "../../public/assets/images/icons/rofda-logo.jpg";
import ClipLoader from "react-spinners/ClipLoader";
import { getCommonDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";
import Header from "./Header";
import ScrollToTop from "../Generic/ScrollTop";
import Home from "../Home";
import Contact from "../Contact";
import OurPartners from "../OurPartners";
import Footer from "./Footer";
import Blogs from "../Blogs";
import Conference from "../Conference";
import Teams from "../Teams";
import Pages from "../Pages/Pages";
import History from "../History";
import Salute from "../Salutes";
import { ScrollToTopIcon } from "../Generic/ScrollToTopIcon";
import Registration from "../Registration";
import Login from "../Login";
import Myaccount from "../Myaccount";
import AllEvents from "../Conference/AllEvents";
import MyEvents from "../Conference/MyEvents";
import FloorPlan from "../Conference/FloorPlan";
import ReqInvitation from "../Registration/ReqInvitation";
import ProtectedRoute from "./ProtectedRoute";
import PastEvents from "../PastEvents/PastEvents";
import Rmn from "../RMN/Rmn";

function Layout() {
  const { appState, dispatch } = useContext(AppContext);
  const { common, commonLoader } = appState;

  useEffect(() => {
    getCommonDetails(appState, dispatch);
  }, []);

  return (
    <>
      {commonLoader ? (
        <div className="home-spinner">
          <img src={Logo} alt="logo" />
          <br />
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        <React.Fragment>
          <Header
            data={common.menu_section}
            location={common.location_section}
            social={common.common_section}
          />
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/contact-us" component={Contact} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/past-events" component={PastEvents} />
              <Route exact path="/our-team" component={Teams} />
              <Route exact path="/history" component={History} />
              <Route exact path="/conference" component={Conference} />
              <ProtectedRoute exact path="/all-events" component={AllEvents} />
              <ProtectedRoute exact path="/my-events" component={MyEvents} />
              <ProtectedRoute exact path="/floor-plan" component={FloorPlan} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/my-account" component={Myaccount} />
              <Route exact path="/registration" component={Registration} />
              <Route exact path="/blogs" component={Blogs} />
              <Route exact path="/rofda-salutes" component={Salute} />
              <Route exact path="/our-partners" component={OurPartners} />
              <Route exact path="/pages/:slug" component={Pages} />
              <Route exact path="/req-invitation" component={ReqInvitation} />
              <Route exact path="/rmn" component={Rmn} />
              <Route exact path="*" component={Home} />
            </Switch>
          </ScrollToTop>
          <Footer
            data={common.footer_section}
            social={common.common_section}
            location={common.location_section}
          />
          <ScrollToTopIcon />
        </React.Fragment>
      )}
    </>
  );
}

export default Layout;
