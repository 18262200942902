import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Col, Form, Row, Modal, Button } from "react-bootstrap";
import { BiArrowFromLeft, BiArrowFromRight } from "react-icons/bi";
import { ClipLoader } from "react-spinners";
import GenericEventDates from "../../validation/GenericEventDates";
import { AppContext } from "../Redux/ContextProvider";
import { Link } from "react-router-dom";

function Preview({ handleStep, data }) {
  // console.log("preview", data);
  // const [showModal, setShowModal] = useState(false);
  const { appState, dispatch } = useContext(AppContext);
  const { registerEvent, registerEventLoader, registerEventMessage } = appState;

  const submitInfo = (action) => {
    handleStep([], action, "preview");
  };

  // const handleClose = () => {
  //   setShowModal(false);
  // };

  const [value, setValue] = useState({
    guestEvents: [],
    events: [],
  });

  useEffect(() => {
    //For Guest Events
    let guest = data.eventsList
      .filter((info) => data.guestEventIds.includes(info.event_id))
      .map((val) => val);

    let event = data.eventsList
      .filter((info) => data.eventIds.includes(info.event_id))
      .map((val) => val);

    setValue({
      ...value,
      guestEvents: guest,
      events: event,
    });
  }, []);

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-lg-8">
        <div className="mb-10">
          <h2>Preview Information:</h2>
        </div>
        <hr />
        <div>
          <div className="mt-20">
            <h5 className="title">Email:</h5>
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="form-inner">
                  <input type="text" value={data.email} disabled />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20">
            <h5 className="title">Information:</h5>
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="form-inner">
                  <input type="text" value={data.fname} disabled />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-inner">
                  <input type="text" value={data.lname} disabled />
                </div>
              </div>
              {data.company !== "" && (
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input type="text" value={data.company} disabled />
                  </div>
                </div>
              )}
              <div className="col-lg-6">
                <div className="form-inner">
                  <input type="text" value={data.address} disabled />
                </div>
              </div>
              {data.mobile && (
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input type="text" value={data.mobile} disabled />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-20">
            <h5 className="title">Package:</h5>
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="form-inner">
                  <input
                    type="text"
                    value={data.selectedTypeName}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-inner">
                  <input
                    type="text"
                    value={data.selectedSubTypeName}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          {data.selectedTypeName === "Sponsor" && (
            <div className="mt-20">
              <h5 className="title">Benefits Included:</h5>
              <span className="textarea">
                {data.optionsList.map((val) => {
                  return (
                    <label className="textarea-content" disabled={true}>
                      {val.title}
                    </label>
                  );
                })}
              </span>
            </div>
          )}
          {data.selectedTypeName === "Sponsor" && (
            <div className="mt-20">
              <h5 className="title">Bonus Options Included:</h5>
              <span className="textarea">
                {data.option
                  .sort((a, b) => a.order_by - b.order_by)
                  .filter((info) =>
                    data.selectedList.includes(info.sponsors_bonus_option_id)
                  )
                  .map((val) => {
                    return (
                      <label className="textarea-content" disabled={true}>
                        {val.title}
                      </label>
                    );
                  })}
              </span>
            </div>
          )}

          {data.selectedSubTypeName !== "Non-attending" && (
            <div className="mt-20">
              <h5 className="title">Events Attending:</h5>
              <GenericEventDates
                component={false}
                selectedEvent={value.events}
              />
            </div>
          )}

          {value.events.some((each) => each.wholesaler_list.length > 0) &&
            data.wholesalerlist &&
            data.wholesalerlist.length > 0 && (
              <div className="mt-20 row">
                <h5 className="title">Wholesalers Events Attending:</h5>
                {/* <li style={{
                                listStyle: 'none', margin: '10 auto', boxShadow: '0 0 0 1px rgb(0 0 0 / 13%)',
                                width: '50%',
                                background: '#fff',
                                overflow: 'hidden',
                                borderRadius: '0.2rem',
                                padding: '15px 14px',
                                textAlign: 'left',
                                verticalAlign: 'center'

                            }}>
                                <label style={{ wordWraprap: 'break-word' }}>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                    </svg>  Selected Time Slot: {data.time_slot}
                                </label>
                            </li> */}
                {data.wholesalerlist.map((wholesaler, idx) => {
                  return (
                    <Col xs="12" sm="6" style={{ textAlign: "left" }}>
                      {/* Try not to add inline CSS. */}
                      <li
                        style={{
                          listStyle: "none",
                          margin: "0 auto",
                          boxShadow: "0 0 0 1px rgb(0 0 0 / 13%)",
                          width: "100%",
                          background: "#fff",
                          overflow: "hidden",
                          borderRadius: "0.2rem",
                          padding: "15px 14px",
                        }}
                      >
                        <label style={{ wordWraprap: "break-word" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-clock-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                          </svg>{" "}
                          {wholesaler.timeslot} {wholesaler.name}
                        </label>
                      </li>
                    </Col>
                  );
                })}
              </div>
            )}
          {data.guest && data.guestFname !== "" && (
            <div className="mt-20">
              <h5 className="title">Guest:</h5>
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      value={data.guestFname}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input type="text" value={data.guestLname} disabled />
                  </div>
                </div>
                {data.guestMobile && (
                  <div className="col-lg-6">
                    <div className="form-inner">
                      <input type="text" value={data.guestMobile} disabled />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {data.guestEventIds && data.guestEventIds.length > 0 && (
            <div className="mt-20">
              <h5 className="title">Guest Event Attending:</h5>
              <GenericEventDates
                component={false}
                selectedEvent={value.guestEvents}
              />
            </div>
          )}
        </div>
        <div className="arrow-container">
          {registerEventLoader ? (
            <div className="mt-20">
              <ClipLoader color={"#00caeb"} loading={true} size={50} />
            </div>
          ) : (
            <React.Fragment>
              <div
                className="col-lg-12 text-center mt-20 arrow"
                onClick={() => submitInfo("previous")}
              >
                <BiArrowFromRight size={30} color={"rgba(0, 202, 235, 0.8)"} />
              </div>
              <div
                className="col-lg-12 text-center mt-20 arrow"
                onClick={() => {
                  submitInfo("next");
                  // setShowModal(true);
                }}
              >
                <BiArrowFromLeft size={30} color={"rgba(0, 202, 235, 0.8)"} />
              </div>
              {/* <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Confirmation</Modal.Title>
                  <Modal.Body>
                    <h4>want to book the rooms ?</h4>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button>Confirm</Button>
                    <Button onClick={handleClose}>
                      <Link to="/login">Cancel</Link>
                    </Button>
                  </Modal.Footer>
                </Modal.Header>
              </Modal> */}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
}

export default Preview;
