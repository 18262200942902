import React, { useState } from "react";
import { BiArrowFromLeft, BiArrowFromRight } from "react-icons/bi";
import { Button, Col, Form, Row } from "react-bootstrap";
import { data } from "jquery";

function Type({ data, handleStep, setData }) {
  const [value, setValue] = useState({
    selectedType: data.selectedType,
    selectedSubType: data.selectedSubType,
    selectedTypeName: data.selectedTypeName,
    selectedSubTypeName: data.selectedSubTypeName,
    first_time_sponosr: "",
  });

  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckBox = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.checked,
    });
  };

  const submitInfo = (action) => {
    let name = data.type
      .filter(
        (info) => info.registration_type_id.toString() === value.selectedType
      )
      .map((val) => val.registration_type_title);

    let subInfo = data.subType
      .filter(
        (info) =>
          info.registration_sub_type_id.toString() === value.selectedSubType
      )
      .map((val) => {
        let information = {
          title: val.title,
          fee:
            parseInt(val.sponsors_fee, 10) + parseInt(val.registration_fee, 10),
        };
        return information;
      });

    let val = {
      ...value,
      selectedTypeName: name && name[0],
      selectedSubTypeName: subInfo.length && subInfo[0].title,
      typeFee: subInfo.length && subInfo[0].fee,
    };
    handleStep(val, action, "type");
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-lg-8">
        <Form>
          <div className="p-17">
            <Row>
              <Col xs={12}>
                <Form.Group className="mt-16">
                  <Form.Label>Registration Type</Form.Label>
                  <Form.Select
                    className="dropDown"
                    name="selectedType"
                    value={value.selectedType}
                    onChange={(e) => {
                      setData({
                        ...data,
                        tempIds: [],
                        eventIds: [],
                        guestEventIds: [],
                        sub_event_list: [],
                        guest_sub_event_list: [],
                        wholesalerlist: [],
                      });
                      handleChange(e);
                    }}
                  >
                    <option value="" disabled>
                      Select type
                    </option>
                    {data.type.map((data, index) => {
                      return (
                        <option
                          value={data.registration_type_id}
                          key={data.registration_type_id}
                        >
                          {data.registration_type_title}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="p-17 mt-20">
            <Row>
              <Col xs={12}>
                <Form.Group className="mt-16">
                  <Form.Label>Registration Sub-Type</Form.Label>
                  <Form.Select
                    disabled={!value.selectedType}
                    className="dropDown"
                    name="selectedSubType"
                    value={value.selectedSubType}
                    onChange={(e) => {
                      setData({
                        ...data,
                        tempIds: [],
                        eventIds: [],
                        guestEventIds: [],
                        sub_event_list: [],
                        guest_sub_event_list: [],
                        wholesalerlist: [],
                      });
                      handleChange(e);
                    }}
                  >
                    <option value="" disabled>
                      Select type
                    </option>
                    {data.subType
                      .filter(
                        (info) =>
                          info.registration_type_id === value.selectedType
                      )
                      .map((data) => {
                        return (
                          <option
                            value={data.registration_sub_type_id}
                            key={data.registration_sub_type_id}
                          >
                            {data.title}{" "}
                            {data.registration_type_id === "2"
                              ? "($" +
                                data.registration_fee +
                                "+" +
                                "$" +
                                data.sponsors_fee +
                                ")"
                              : ""}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </div>

          {value.selectedType === "2" ? (
            <div className="p-17 mt-20">
              <Row>
                <Col xs={12}>
                  <Form.Check
                    type="checkbox"
                    label="Are you a first time company to sponsor?"
                    id="custom-switch"
                    name="first_time_sponosr"
                    checked={value.first_time_sponosr}
                    onChange={(e) => handleCheckBox(e)}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            ""
          )}
        </Form>
        <div className="arrow-container">
          <div
            className="col-lg-12 text-center mt-20 arrow"
            onClick={() => submitInfo("previous")}
          >
            <BiArrowFromRight size={30} color={"rgba(0, 202, 235, 0.8)"} />
          </div>
          <div
            className="col-lg-12 text-center mt-20 arrow"
            onClick={() => submitInfo("next")}
            disabled={!value.selectedType || !value.selectedSubType}
          >
            <BiArrowFromLeft size={30} color={"rgba(0, 202, 235, 0.8)"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Type;
