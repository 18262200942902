import React, { useContext, useEffect, useRef, useState } from "react";
import AddressArea from "./AddressArea";
import Breadcrumb from "../Generic/Breadcrumb";
import ContactForm from "./ContactForm";
import { ClipLoader } from "react-spinners";
import { getContactUsDetails } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";

const Contact = () => {
    const { appState, dispatch } = useContext(AppContext);
    const { contact, contactLoader } = appState;
    const myRef = useRef(null);

    useEffect(() => {
        getContactUsDetails(appState, dispatch)
    }, [])

    return (
        <React.Fragment>
            
            {
                contactLoader ?
                    <div className='home-spinner'>
                        <ClipLoader color={"#00caeb"} loading={true} size={100} />
                    </div>
                    :
                    Object.keys(contact).length &&
                    <React.Fragment>
                        <Breadcrumb
                            pageName={contact.header_contact_section.title}
                            pageTitle={contact.header_contact_section.title}
                            id={`#${contact.header_contact_section.title}`}
                            image={contact.header_contact_section.banner_image}
                            myRef={myRef}
                        />
                        <div ref={myRef}>
                            <AddressArea
                                contact={contact.contact_section}
                                locations={contact.location_list}
                            />
                            <ContactForm
                                contact={contact.other_contact_section}
                                contact_question_option={contact.contact_question_option}
                            />
                        </div>
                    </React.Fragment>
            }
        </React.Fragment>
    );
}
export default Contact;