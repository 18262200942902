import React, { useState } from "react";
import axios from "axios";
import subscribeValidate from '../../validation/LoginFormValidationRules';
import useForm from "../../validation/useForm";


function ContactForm({ contact, contact_question_option }) {

  const [res, setRes] = useState([])
  const [errflag, setErrflag] = useState(false)
  const [successflag, setSuccessflag] = useState(false)

  const login = (data) => {

    const { fname, ph_no, email, subject, contact_question_option, referred_by, message } = data;

    const body = {
      ClientId: 48,
      full_name: fname,
      email_id: email,
      phone_number: ph_no,
      subject: subject,
      contact_question_option: contact_question_option,
      referred_by: referred_by,
      message: message ? message : "Test Message",
    };

    let requestURL = process.env.REACT_APP_URL + "SendContactUs"
    axios.post(requestURL, body).then(res => {

      if (res.data.status === 'success') {
        setTimeout(() => { clearField() }, 2000);
        setRes(res.data)
        setSuccessflag(!errflag)
      }
      else {
        //setTimeout(() => { clearField() }, 2000);
        setRes(res.data)
        setErrflag(!errflag)
      }

    }).catch(error => {
      console.log(error)
    })

  }
  const clearMessage = () => {
    setErrflag(!errflag)
  }
  const clearSuccessmag = () => {
    setSuccessflag(!successflag)

  }

  const {
    values,
    handleChange,
    handleSubmit,
    clearField,
    errors
  } = useForm(login, subscribeValidate);

  return (
    <div className="form-section pt-120 pb-120" id="contact">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6">
            <div className="section-title primary4">
              <span>-{contact.title}-</span>
              <h3>{contact.highlist_title}</h3>
              <p className="para">
                {contact.sub_title}
              </p>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8">
            <form onSubmit={handleSubmit}>
              <div className="row g-4">
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="fname"
                      placeholder="Your Name: *"
                      onChange={handleChange}
                      value={values.fname || ''}
                      className={`input ${errors.fname && 'is-danger'}`}
                    />
                    {errors.fname && (
                      <p className="help is-danger" style={{
                        color: 'red',
                        fontSize: '12px',
                        padding: '2px'
                      }}>{errors.fname}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="email"
                      placeholder="Your E-mail:"
                      onChange={handleChange}
                      value={values.email || ''}
                    />
                    {errors.email && (
                      <p className="help is-danger" style={{
                        color: 'red',
                        fontSize: '12px',
                        padding: '2px'
                      }}>{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input
                      type="text"
                      name="ph_no"
                      placeholder="Phone Number:"
                      onChange={handleChange}
                      value={values.ph_no || ''}
                    />
                    {errors.ph_no && (
                      <p className="help is-danger" style={{
                        color: 'red',
                        fontSize: '12px',
                        padding: '2px'
                      }}>{errors.ph_no}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input type="text" name="subject" placeholder="Subject:"
                      onChange={handleChange}
                      value={values.subject || ''}

                    />
                    {errors.subject && (
                      <p className="help is-danger" style={{
                        color: 'red',
                        fontSize: '12px',
                        padding: '2px'
                      }}>{errors.subject}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    {/* <input type="text" name="subject" placeholder="Subject:"
                        onChange={handleChange}
                        value={values.subject || ''}
                       
                      /> */}
                    <select name="contact_question_option" className="select_questions" style={{
                      border: '1px solid #dddddd',
                      borderRadius: '5px',
                      padding: '15px 15px',
                      width: '100%',
                      transition: 'all 0.5s ease'
                    }} onChange={handleChange}>
                      <option style={{
                        fontSize: '14px',
                        fontWeight: 400,

                        fontFamily: "Poppins"
                      }}

                      >Select Option</option>
                      {
                        contact_question_option.map((val, index) => {
                          return (
                            <option value={val.title}>{val.title}</option>
                          )

                        }
                        )
                      }

                    </select>
                    {errors.contact_question_option && (
                      <p className="help is-danger" style={{
                        color: 'red',
                        fontSize: '12px',
                        padding: '2px'
                      }}>{errors.contact_question_option}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input type="text" name="referred_by" placeholder="Where did you hear about us?"
                      onChange={handleChange}
                      value={values.referred_by || ''}
                    />
                    {errors.referred_by && (
                      <p className="help is-danger" style={{
                        color: 'red',
                        fontSize: '12px',
                        padding: '2px'
                      }}>{errors.referred_by}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-inner">
                    <textarea
                      name="message"
                      cols={30}
                      rows={6}
                      placeholder="Write A Question "
                      defaultValue={""}
                      onChange={handleChange}
                      value={values.message || ''}
                    />
                  </div>
                </div>
                {
                  Object.keys(errors).length === 0 && successflag &&
                  (<p style={{ color: 'green', fontSize: '16px' }}>{res.message}
                    <button onClick={clearSuccessmag} className="close-modal-button" style={{
                      position: 'relative',
                      right: '-1%',
                      top: '-32%',
                      zIndex: '9',
                      backgroundColor: '#fff',
                      borderRadius: '3em',
                      height: '2em',
                      width: '2em',
                      boxShadow: '0 0 10px rgb(0 0 0 / 50%)',
                      color: 'green'
                    }}>X</button>
                  </p>)
                }
                {
                  Object.keys(errors).length === 0 && errflag &&
                  (<p style={{ color: 'red', fontSize: '16px' }}>{res.message}
                    <button onClick={clearMessage} className="close-modal-button" style={{
                      position: 'relative',
                      right: '-1%',
                      top: '-32%',
                      zIndex: '9',
                      backgroundColor: '#fff',
                      borderRadius: '3em',
                      height: '2em',
                      width: '2em',
                      boxShadow: '0 0 10px rgb(0 0 0 / 50%)',
                      color: '#ba3242'
                    }}>X</button></p>)
                }
                <div className="col-lg-12 text-center">
                  <input
                    type="submit"
                    defaultValue=""
                    value="Send Now"
                    className="eg-btn btn--submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
