export default function subscribeValidate(values) {

    let errors = {};
  
     if (!values.email) {
      errors.email = 'Email can\'t be blank';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
  
     
  
    return errors;
  };