import React, { useContext, useEffect, useState } from "react";
import { BiArrowFromLeft } from "react-icons/bi";
import { ClipLoader } from "react-spinners";
import { regexEmail } from "../../validation/auth";
import { verifyEmail } from "../Redux/API";
import { AppContext } from "../Redux/ContextProvider";

function EmailVerification({
    handleStep
}) {
    const { appState, dispatch } = useContext(AppContext);
    const [email, setEmail] = useState("")
    const [error, setError] = useState("");
    const { emailVerification, emailVerificationLoader, emailVerificationMessage,emailVerificationStatusMessage } = appState;

    const handleChange = (e) => {
        setError("")
        setEmail(e.target.value)
    }

    const onEmailVerification = () => {
        if (regexEmail(email))
            dispatch(verifyEmail(appState, dispatch, email))
        else setError("Please enter a valid email format.")
    }
    console.log('emailVerification',emailVerificationMessage)

    useEffect(() => {
        if (emailVerificationMessage !== "")
            if (emailVerificationMessage === "success") {
                let info = emailVerification;
                info = {
                    ...info,
                    email: email
                }
                handleStep(info, "next", "email")
            }
            else {
                setError(emailVerificationStatusMessage)
            }
    }, [emailVerification])

    return (
        <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
                        <form onSubmit={(e)=>e.preventDefault()}>
                    <div className="row g-4">
                        <div className="col-lg-12">
                            <div className="form-inner">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Please validate your email"
                                            onChange={handleChange}
                                            value={email || ''}
                                    className={`input ${error && 'is-danger'}`}
                                        />
                                {            
                                            error && (
                                
                                    <p className="help is-danger" style={{
                                                color: 'red',
                                                fontSize: '12px',
                                                padding: '2px'
                                            }}>{error}</p>
                                        )}
                                    </div>
                                </div>
                        <div className="arrow-container">
                                    {
                                        emailVerificationLoader ?
                                    <div className="mt-20">
                                                <ClipLoader color={"#00caeb"} loading={true} size={50} />
                                            </div>
                                            :
                                            <div
                                        className="col-lg-12 text-center mt-20 arrow"
                                                onClick={onEmailVerification}
                                            >
                                                <BiArrowFromLeft
                                                    size={30}
                                                    color={"rgba(0, 202, 235, 0.8)"}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
    );
}

export default EmailVerification;
