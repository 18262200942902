import React, { useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";

const GenericEventDates = ({ selectedEvent, ids, component, handleEvents }) => {
  const [showModal, setShowModal] = useState(false);
  const [viewMoreindex, setViewMoreIndex] = useState();

  const handleClose = () => setShowModal(false);

  return (
    <Row>
      {selectedEvent.map((val, i) => {
        return (
          <React.Fragment>
            <Col xs={4} key={i}>
              <div
                className="container container-content"
                style={{
                  borderColor:
                    component &&
                    val.is_default_event === "0" &&
                    ids.includes(val.event_id) &&
                    "#4BB543",
                  borderWidth: 2,
                }}
                disabled={val.is_default_event === "1"}
                onClick={() =>
                  component &&
                  val.is_default_event === "0" &&
                  handleEvents(val.event_id)
                }
              >
                <h6>
                  <svg
                    style={{ color: "#0d6efd" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-calendar-check-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                  </svg>{" "}
                  {val.event_date}
                </h6>
                <h4>
                  <span>{val.event_name}</span>
                </h4>

                <p title={val.short_description}>
                  {val?.short_description.length > 65
                    ? val.short_description.substring(0, 65)
                    : val.short_description}
                  <span
                    style={{
                      cursor: "pointer",
                      color: "green",
                      fontSize: "15px",
                    }}
                    onClick={() => {
                      setShowModal(true);
                      setViewMoreIndex(i);
                    }}
                  >
                    {val?.short_description.length > 75 && "  ... View More"}
                  </span>
                </p>

                {val.wholesaler_list.length > 0 &&
                  val.allow_company_list === "1" && (
                    <h6 className="wholesaler">
                      Wholesaler Enabled{" "}
                      <svg
                        style={{ color: "#0d6efd" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </h6>
                  )}
                <div className="event-date">
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    </svg>{" "}
                    {val.event_start_time}
                  </span>{" "}
                  -
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-clock-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    </svg>{" "}
                    {val.event_end_time}
                  </span>
                </div>
                {val.is_enabled_for_guest_label === "Yes" && (
                  <h6 className="guest">
                    Guest{" "}
                    {/* <svg style={{ color: "#0d6efd" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg> */}
                  </h6>
                )}
              </div>
            </Col>
            <Modal
              show={showModal}
              onHide={handleClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header
                closeButton
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                }}
              >
                <Modal.Title id="contained-modal-title-vcenter">
                  <h6>
                    <svg
                      style={{ color: "#0d6efd" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-calendar-check-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                    </svg>{" "}
                    {selectedEvent[viewMoreindex]?.event_date}
                  </h6>
                  {val.wholesaler_list.length > 0 && (
                    <h6 className="wholesaler">
                      Wholesaler Enabled{" "}
                      <svg
                        style={{ color: "#0d6efd" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>
                    </h6>
                  )}

                  {val.is_enabled_for_guest_label === "Yes" && (
                    <h6 className="guest">
                      Guest{" "}
                      {/* <svg style={{ color: "#0d6efd" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg> */}
                    </h6>
                  )}
                </Modal.Title>
                <Modal.Title>
                  <div className="event-date">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-clock-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      </svg>{" "}
                      {selectedEvent[viewMoreindex]?.event_start_time}
                    </span>{" "}
                    -
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-clock-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      </svg>{" "}
                      {selectedEvent[viewMoreindex]?.event_end_time}
                    </span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h4>{selectedEvent[viewMoreindex]?.event_name}</h4>
                <p>{selectedEvent[viewMoreindex]?.short_description}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleClose} variant="danger">
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

export default GenericEventDates;
