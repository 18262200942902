import { createContext, useReducer } from 'react';
import { initialState } from './INITIAL_STATE';
import { reducer } from './Reducer';

export const AppContext = createContext();

const ContextProvider = (props) => {
    const [appState, dispatch] = useReducer(reducer, initialState);
    return (
        <AppContext.Provider value={{ appState, dispatch }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default ContextProvider