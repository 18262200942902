import React, { useState } from "react";
import BannerSpring from "../../public/assets/images/icons/banner2-spring.svg";
import BannerCircle from "../../public/assets/images/icons/banner2-circle.svg";
import BannerVector from "../../public/assets/images/bg/banner2-vector.png";
import SwiperCore, {
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
} from "swiper";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/effect-fade";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import Video from "../Generic/Video";
SwiperCore.use([Navigation, Pagination, Autoplay, Navigation, EffectFade]);

function Banner({ banner, social }) {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setURL] = useState("");
  const banner2Slide = {
    slidesPerView: "auto",
    speed: 3500,
    loop: true,
    spaceBetween: 10,
    centeredSlides: false,
    roundLengths: true,
    parallax: true,
    effect: "fade",
    navigation: false,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: 6500,
    },
    pagination: {
      el: ".banner2-pagination",
      clickable: true,
    },
  };

  const BackGroudnImage = styled.div`
    &:before {
      background-image: url(${(props) => props.image});
    }
  `;

  const handleVideoModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div className="banner-section2">
        <img
          src={BannerSpring}
          className="banner2-spring1"
          alt="BannerSpring"
        />
        <img
          src={BannerSpring}
          className="banner2-spring2"
          alt="BannerSpring"
        />
        <img
          src={BannerCircle}
          className="banner2-circle1"
          alt="BannerCircle"
        />
        <img
          src={BannerCircle}
          className="banner2-circle2"
          alt="BannerCircle"
        />
        <img src={BannerVector} className="banner2-vector" alt="BannerVector" />

        <ul className="banner-social2 gap-5">
          {social.twitter_url && (
            <li>
              <a
                href={`${social.twitter_url}`}
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
            </li>
          )}

          {social.facebook_url && (
            <li>
              <a
                href={`${social.facebook_url ? social.facebook_url : ""}`}
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
          )}
          {social.instagram_url && (
            <li>
              <a
                href={`${social.instagram_url}`}
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
          )}

          {social.linkedin_url && (
            <li>
              <a
                href={`${social.linkedin_url}`}
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
          )}
        </ul>

        <Swiper {...banner2Slide} className="swiper banner2">
          <div className="swiper-wrapper">
            {banner.map((info, index) => {
              return (
                <SwiperSlide className="swiper-slide" key={index}>
                  <BackGroudnImage
                    className="slider-bg-1"
                    image={info.bg_image}
                  >
                    <div className="container">
                      <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-xl-7 col-lg-10">
                          <div className="banner2-content">
                            <span>{info.slider_title1}</span>
                            {/* h1 element size increased for better display in home page */}
                            <h1 style={{ fontSize: "40px" }}>
                              {info.slider_title2}
                            </h1>
                            <p>{info.slider_title3}</p>

                            {info.video_type === "youtube" ? (
                              <Link>
                                <a
                                  className="eg-btn btn--primary2 btn--lg"
                                  onClick={() => {
                                    setURL(info.video_url);
                                    handleVideoModal();
                                  }}
                                  href={(e) => e.preventDefault}
                                >
                                  {info.button_title}
                                </a>
                              </Link>
                            ) : (
                              // <Link className="eg-btn btn--primary2 btn--lg" rel="" target={info.is_open_self === "1" ?"_blank" :''}
                              //      to={info.button_url}>
                              //   {info.button_title}
                              // </Link>

                              <>
                                {info.is_open_self === "1" ||
                                info.is_link === "1" ? (
                                  <a
                                    key={info.button_title}
                                    href={info.button_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="eg-btn btn--primary2 btn--lg"
                                  >
                                    {info.button_title}
                                  </a>
                                ) : (
                                  <Link
                                    className="eg-btn btn--primary2 btn--lg"
                                    rel=""
                                    to={info.button_url}
                                  >
                                    {info.button_title}
                                  </Link>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </BackGroudnImage>
                </SwiperSlide>
              );
            })}
          </div>
        </Swiper>
        <div className="banner2-pagination d-flex justify-content-center flex-row align-items-center gap-2" />
      </div>
      {isOpen && <Video handleVideoModal={handleVideoModal} url={url} />}
    </React.Fragment>
  );
}

export default Banner;
