export const initialState = {
  contact: {},
  contactLoader: true,
  contactMessage: "",

  aboutUs: {},
  aboutUsLoader: true,
  aboutUsMessage: "",

  ourTeam: {},
  ourTeamLoader: true,
  ourTeamMessage: "",

  conference: {},
  conferenceLoader: true,
  conferenceMessage: "",

  history: {},
  historyLoader: true,
  historyMessage: "",

  blog: {},
  blogLoader: true,
  blogMessage: "",

  common: {},
  commonLoader: true,
  commonMessage: "",

  home: {},
  homeLoader: true,
  homeMessage: "",

  salute: {},
  saluteLoader: true,
  saluteMessage: "",

  storedValues: {},

  partner: {},
  partnerLoader: true,
  partnerMessage: "",

  emailVerification: {},
  emailVerificationLoader: false,
  emailVerificationMessage: "",
  emailVerificationStatusMessage: "",

  eventDates: {},
  eventDatesLoader: false,
  eventDatesMessage: "",

  registerEvent: {},
  registerEventLoader: false,
  registerEventMessage: "",

  states: {},
  stateEventLoader: false,
  sateEventMessage: "",

  submitInvitationReqEvent: {},
  submitInvitationReqEventLoader: false,
  submitInvitationReqEventMessage: "",

  loginInfo: {},
  loginLoader: false,
  loginMessage: "",

  eventsListInfo: {},
  eventsListLoader: false,
  eventsListMessage: "",

  RMNData: {},
  RMNLoader: false,
  RMNMessage: "",
};
