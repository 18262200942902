import React from "react";

function Highlights(service) {
  return (
    <>
      <div className="service2-section pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-title2 primary4">
                <span>-{service.service.highlist_title}-</span>
                <div>
                  <h3>{service.service.sub_title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center g-4">
            {
                service.service.services_list && service.service.services_list.length &&
                service.service.services_list.map((service,index) => {
                    return (
                        <div key={service.title} className="col-lg-4 col-md-6 col-sm-10">
              <div
                className="service-item2 wow fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.2s"
              >
                {
                    service.icon_image && service.icon_image.length &&
                    <img
                  src={service.icon_image}
                  className="service21-icon"
                  alt = "service21"
                />
                }
                
                <div className="service-content">
                  {/* <span>{index + 1}</span> */}
                  <span><h4>
                    
                      <a href={`/${service.target_url}`}>{service.title}</a>
                    


                  </h4></span>
                  <p className="para">
                  {service.short_description}
                  </p>
                </div>
              </div>
            </div>
                    )
                })
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default Highlights;
