import React from "react";

function Team(team) {
  return (
    <React.Fragment>
      {
        Object.keys(team).length &&
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="section-title2 primary4">
                <div>
                  <h3>{team.team.highlist_title}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            {
              team.team.services_list && team.team.services_list.length &&
              team.team.services_list.map(member => {
                return (
                  <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
                    <div
                      className="single-team2 wow fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.2s"
                    >
                      <div className="team-image">
                        <img src={member.icon_image} alt="member" />
                        <div className="social-area gap-3">
                          <ul className="social-links d-flex justify-content-center align-items-center flex-row gap-3">
                            {member.instagram_url && (

                              <li>
                                <a href={`${member.instagram_url}`} target="_blank" rel="noreferrer">
                                  <i className="bx bxl-instagram" />
                                </a>
                              </li>)
                            }
                            {member.facebook_url && (

                              <li>
                                <a href={`${member.facebook_url}`} target="_blank" rel="noreferrer">
                                  <i className="bx bxl-facebook" />
                                </a>
                              </li>
                            )}
                            {member.twitter_url && (

                              <li>
                                <a href={`${member.twitter_url}`} target="_blank" rel="noreferrer">
                                  <i className="bx bxl-twitter" />
                                </a>
                              </li>
                            )}
                            {member.pinterest_url && (

                              <li>
                                <a href={`${member.pinterest_url}`} target="_blank" rel="noreferrer">
                                  <i className="bx bxl-pinterest-alt" />
                                </a>
                              </li>

                            )}

                            {member.linkedin_url && (

                              <li>
                                <a href={`${member.linkedin_url}`} target="_blank" rel="noreferrer">
                                  <i className="bx bxl-linkedin" />
                                </a>
                              </li>
                            )}

                          </ul>
                        </div>
                      </div>
                      <div className="team-content">
                        <h4 className="name">{member.title}</h4>
                        <p className="designation">{member.services_sub_title}</p>
                        <p className="designation">{member.sub_section_title2 ? member.sub_section_title2 : ''}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default Team;
