import React, { useEffect, useState } from "react";
import { BiArrowFromLeft, BiArrowFromRight } from "react-icons/bi";

function Options({ handleStep, data, setData }) {
  const [value, setValue] = useState({
    optionsList: data.optionsList,
    selectedList: data.selectedList,
    wholesalerIds: data.wholesalerlist,
    timeslot: data.time_slot,
    bonusFee: data.bonusFee,
  });

  const submitInfo = (action) => {
    handleStep(value, action, "list");
  };

  const handleClick = (data) => {
    let array = value.selectedList;
    if (array.includes(data)) {
      const index = array.indexOf(data);
      array.splice(index, 1);
      setValue({
        ...value,
        selectedList: array,
      });
    } else
      setValue({
        ...value,
        selectedList: [...value.selectedList, data],
      });
  };

  useEffect(() => {
    let i;
    let option = data.option;
    let fee = 0;
    for (i = 0; i < option.length; i++) {
      if (
        value.selectedList.includes(option[i].sponsors_bonus_option_id) &&
        option[i].is_collect_amount === "1"
      ) {
        fee = fee + parseInt(option[i].cost_fee, 10);
      }
    }
    setValue({
      ...value,
      bonusFee: fee,
    });
  }, [value.selectedList]);

  useEffect(() => {
    data.subType
      .filter((info) => info.registration_sub_type_id === data.selectedSubType)
      .map((val) =>
        setValue({
          ...value,
          optionsList: val.sponsors_benefit_list,
        })
      );
  }, []);

  useEffect(() => {
    setData({
      ...data,
      tempIds: [],
      eventIds: [],
      guestEventIds: [],
      sub_event_list: [],
      guest_sub_event_list: [],
      wholesalerlist: [],
    });
  }, []);

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-lg-8">
        <form>
          <div className="row g-4">
            <div className="col-lg-12">
              <div className="form-inner">
                <div className="mb-10">Benefits included</div>
                <span className="textarea">
                  {value.optionsList.map((val) => {
                    return (
                      <label className="textarea-content" disabled={true}>
                        {val.title}
                      </label>
                    );
                  })}
                </span>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-inner">
                <div className="mb-10">Bonus options available : </div>
                <span className="textarea">
                  {data.option
                    .sort((a, b) => a.order_by - b.order_by)
                    .map((val) => {
                      return (
                        <label
                          style={{
                            backgroundColor:
                              value.selectedList.includes(
                                val.sponsors_bonus_option_id
                              ) && "#4BB543",
                          }}
                          className="textarea-content-unselect"
                          disabled={true}
                          onClick={() =>
                            handleClick(val.sponsors_bonus_option_id)
                          }
                        >
                          {val.title} ( ${val.cost_fee} )
                        </label>
                      );
                    })}
                </span>
              </div>
            </div>
            <div className="arrow-container">
              <div
                className="col-lg-12 text-center mt-20 arrow"
                onClick={() => submitInfo("previous")}
              >
                <BiArrowFromRight size={30} color={"rgba(0, 202, 235, 0.8)"} />
              </div>
              <div
                className="col-lg-12 text-center mt-20 arrow"
                onClick={() => submitInfo("next")}
              >
                <BiArrowFromLeft size={30} color={"rgba(0, 202, 235, 0.8)"} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Options;
