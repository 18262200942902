import React, { useContext, useState, useEffect } from "react";
// import { ToastContainer } from "react-bootstrap";
import Breadcrumb from "../Generic/Breadcrumb";
import { loginRequest } from "../Redux/API";
import { ClipLoader } from "react-spinners";
import { AppContext } from "../Redux/ContextProvider";
import { Link, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiArrowFromLeft } from "react-icons/bi";

function Login() {
  const { appState, dispatch } = useContext(AppContext);
  const isAuthenticated = localStorage.getItem("loggedInEmail");
  const { loginInfo, loginLoader, loginMessage } = appState;
  const [loginInput, setLoginInput] = useState({
    ClientId: 48,
    email_id: "",
  });
  const [error, setError] = useState("");
  const history = useHistory();
  // console.log(history?.location.state?.IndirectAccess);

  useEffect(() => {
    scrollTo();
  });

  useEffect(() => {
    if (loginMessage !== "") {
      setError(loginMessage);
    }
    if (loginInfo.status === "success") {
      history.push("/");
    }
  }, [loginMessage, loginInfo]);

  const scrollTo = () => {
    window.scrollTo({
      top: 120,
      behavior: "smooth",
    });
  };

  const login = (e) => {
    e.preventDefault();
    loginRequest(appState, dispatch, loginInput);
  };

  const loginData = (e) => {
    setError("");
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    isAuthenticated === null &&
      history.location.state?.IndirectAccess === true &&
      toast("Please Login to access this page");
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      {loginLoader ? (
        <div className="home-spinner">
          <ClipLoader color={"#00caeb"} loading={true} size={100} />
        </div>
      ) : (
        <React.Fragment>
          <Breadcrumb pageName={"Login"} pageTitle={"Login"} id={`#$Login`} />
          <ToastContainer />
          <div className="form-section pt-120 pb-120" id="login" ref={scrollTo}>
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-8">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row g-4">
                      <div className="col-lg-12">
                        <div className="form-inner">
                          <label
                            htmlFor="email"
                            style={{ marginRight: "10px" }}
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            name="email_id"
                            id="email"
                            placeholder="Please Enter your email"
                            className={`input ${"is-danger"}`}
                            onChange={loginData}
                          />
                          {error && (
                            <p
                              className="help is-danger"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                padding: "2px",
                              }}
                            >
                              {error}
                            </p>
                          )}
                        </div>

                        <div>
                          <button
                            className="col-lg-2  header-btn btn--primary2 text-center mt-20 "
                            onClick={login}
                          >
                            <BiArrowFromLeft size={30} color={"white"} />
                            Login
                          </button>
                        </div>
                      </div>
                      <Link
                        to="/registration"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        Click for Registration
                      </Link>
                      <Link
                        to="/"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        Continue as Guest
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default Login;
