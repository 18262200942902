import React, { useContext, useEffect, useRef} from "react";
import { getCommonDetails } from "../../components/Redux/API";
import { AppContext } from "../../components/Redux/ContextProvider";
import { useParams } from "react-router-dom";
import Breadcrumb from "../Generic/Breadcrumb";

function Pages() {
    const { slug } = useParams();
    const { appState, dispatch } = useContext(AppContext);
    const { common, commonLoader } = appState;
    const myRef = useRef(null);

    useEffect(() => {
        getCommonDetails(appState, dispatch)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const page_Section = common ? common.footer_section.page_section.filter(el => el.slug === slug) : [];
    return (
        <>
            {
                !!page_Section && (
                    <>
                        <Breadcrumb pageName={page_Section[0].title} pageTitle={page_Section[0].title} 
                          image={page_Section[0].banner_image}
                          myRef={myRef} />

<div ref={myRef}>
                       
                        <div className="blog-grid-section pt-120 pb-120" id="blog-grid">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-6">
                                        <div className="section-title2 primary6">
                                            <span>-{page_Section[0].title}-</span>
                                            <div>
                                                {/* <h3>{blog.highlist_title}</h3> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row position-relative justify-content-center g-4">

                                    <p dangerouslySetInnerHTML={{
                                        __html: page_Section
                                            ? page_Section[0].description
                                            : ""
                                    }}>

                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>
                    </>
                )

            }
        </>
    );
}

export default Pages;
